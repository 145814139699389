import { emitSocketEvent } from '@/services/global/globalUtils';
import { useEffect } from 'react';
import type { DeviceColorWithTvAndMediator } from '@/types/global/types';

export const useDynamicTabletName = (
    color: DeviceColorWithTvAndMediator,
    title: string
): void => {
    const deviceTabIcon: Record<DeviceColorWithTvAndMediator, string> = {
        red: '🔴',
        blue: '🔵',
        orange: '🟠',
        green: '🟢',
        tv: '📺',
        mediator: '⭐',
    };

    useEffect(() => {
        document.title = `${deviceTabIcon[color]} ${title}`;
        emitSocketEvent('send_synchronize_device', { deviceColor: color });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
