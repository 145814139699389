import { emitSocketEvent } from '@/services/global/globalUtils';
import type { Game4Tablets, Substance } from '@/types/games/gameFour/types';

export function handleItemsSelectedGameFour(
    color: Game4Tablets,
    substance: Substance
): void {
    emitSocketEvent('send_items_selected_game_four', {
        color,
        substance,
    });
}
