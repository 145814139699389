import { TVPlaceholder } from '@/assets/images/global';
import { CarCrash } from '@/assets/images/icons';
import styles from '@/features/components/gameFour/gameFour.module.scss';
import { mapTabletColorToName } from '@/services/global/globalUtils';
import { useGame4Store } from '@/store/games/game4Store';
import { motion } from 'framer-motion';
import type { Game4Tablets } from '@/types/games/gameFour/types';
import data from '@/data/gameFourData.json';

export default function GameFour() {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img src={CarCrash} alt='car crash icon' />
                <div>
                    <p>{data.texts.crash}</p>
                    <p>{data.texts.description}</p>
                </div>
            </div>
            <div className={styles.content}>
                <img
                    src={TVPlaceholder}
                    alt='car placeholder for the tv'
                    width={800}
                    height={800}
                />
                <div>
                    <ItemSelection color='blue' />
                    <div className={styles.separator} />
                    <ItemSelection color='orange' />
                </div>
            </div>
        </div>
    );
}

function ItemSelection({ color }: { color: Game4Tablets }) {
    const itemsSelected = useGame4Store.use.itemsSelected();
    const substance = itemsSelected[color];

    const colorToDisplay = mapTabletColorToName(color);

    return (
        <div className={styles.itemSelectionContainer}>
            <p>
                {data.texts.vehicle} {colorToDisplay}
            </p>
            <motion.button
                whileTap={{
                    scale: 0.9,
                }}
            >
                <div
                    className={
                        styles[
                            `${'substanceIconContainer'}${substance ? substance.slug.charAt(0).toUpperCase() + substance.slug.slice(1) : ''}`
                        ]
                    }
                >
                    {substance?.image && (
                        <img
                            src={substance.image}
                            alt='icone de la substance'
                            width={28}
                            height={32}
                        />
                    )}
                </div>
                <p>{substance?.name ?? data.texts.waitingSelection}</p>
            </motion.button>
        </div>
    );
}
