import { useGame2Store } from '@/store/games/game2Store';
import styles from '@/features/components/gameTwo/gameTwo.module.scss';

export default function GameTwoCar() {
    const selectedItems = useGame2Store.use.selectedCarObjects();
    const positionsObjects = Object.values(selectedItems);

    const locations = positionsObjects
        .map((position) => Object.entries(position))
        .flat();

    return (
        <div className={styles.carContainer}>
            <h1>Chargement du vehicule en cours...</h1>
            <div className={styles.car}>
                <div className={styles.innerCar}>
                    {locations.map((location, index) => {
                        return (
                            <div
                                key={location[0]}
                                className={styles[`${location[0]}Circle`]}
                            >
                                {location[1]
                                    ? location[1].name.charAt(0)
                                    : index + 1}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div></div>
        </div>
    );
}
