import CreateRoom from '@/features/components/waitingPlayers/CreateRoom';
import BlueActionSwitch from '@/features/devices/tablets/blue/BlueActionSwitch';
import GreenActionSwitch from '@/features/devices/tablets/green/GreenActionSwitch';
import MediatorTablet from '@/features/devices/tablets/mediator/MediatorTablet';
import OrangeActionSwitch from '@/features/devices/tablets/orange/OrangeActionSwitch';
import RedActionSwitch from '@/features/devices/tablets/red/RedActionSwitch';
import Tv from '@/features/devices/tv/Tv';
import { useCurrentDevice } from '@/hooks/useCurrentDevice';
import {
    handleGoToGameStatus,
    handleTirePressureValidation,
} from '@/services/global/globalHandleFunctions';
import { emitSocketEvent, isDevelopperMode } from '@/services/global/globalUtils';
import SocketService from '@/services/SocketServices';
import {
    setDeviceActivatedStatus,
    setDeviceConnectedStatus,
} from '@/store/deviceStore';
import {
    resetTirePressure,
    setDoNotDisturb,
    setIsBeeingCalled,
    setSeatBeltStatus,
    setStartEngine,
    setTirePressure,
    setTirePressureValidation,
    setUnlockState,
} from '@/store/games/game1Store';
import {
    setSelectedCarObject,
    setSelectedTruckObject,
} from '@/store/games/game2Store';
import { setSelectedSubstance } from '@/store/games/game4Store';
import { setCurrentSituation, setPlayersAnswer } from '@/store/games/game5Store';
import {
    setCockpitStatus,
    setGameResult,
    setGameStatus,
    setIsAudioPlaying,
    setTarget,
    setVehicle,
} from '@/store/gameStore';
import {
    setCurrentOverlayDescriptions,
    setCurrentOverlayIndex,
    setCurrentOverlayType,
} from '@/store/overlayStore';
import { setRoomName } from '@/store/roomStore';
import { useCallback, useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import type { OverlayDescription, OverlayType } from '@/types/games/types';
import {
    GAMESTATUS,
    type DeviceColorWithTvAndMediator,
    type GameStatus,
} from '@/types/global/types';

export default function App() {
    const [room, setRoom] = useState('');
    const socket = SocketService.getInstance().getSocket();
    const device = useCurrentDevice();

    const handleInitGameDevMode = useCallback(
        (deviceColor: DeviceColorWithTvAndMediator) => {
            setDeviceConnectedStatus(deviceColor, true);
            setRoom('devRoom');
            emitSocketEvent('send_join_room', { device: deviceColor });
        },
        []
    );

    // If developper mode is on, it sets the room to devRoom then connects the devices
    useEffect(() => {
        if (isDevelopperMode) {
            setRoomName('devRoom');
            handleInitGameDevMode(device);
        }
    }, [handleInitGameDevMode, device]);

    useEffect(() => {
        socket.on(
            'receive_start_game',
            (data: { roomName: string; color: DeviceColorWithTvAndMediator }) => {
                setDeviceConnectedStatus(data.color, true);
                if (data.color === 'mediator') {
                    setRoomName(data.roomName);
                    handleGoToGameStatus(GAMESTATUS.WELCOME_SCREEN);
                }
            }
        );
        socket.on('receive_game_status', (data: { status: GameStatus }) => {
            setGameStatus(data.status);
        });
        socket.on('receive_game_result', ({ result }) => {
            setGameResult(result);
        });
        socket.on(
            'receive_device_activated_status',
            (data: { deviceColor: string; status: boolean }) => {
                setDeviceActivatedStatus(
                    data.deviceColor as DeviceColorWithTvAndMediator,
                    data.status
                );
            }
        );
        socket.on('receive_reset', () => {
            location.reload();
        });
        socket.on(
            'receive_is_audio_playing',
            (data: { isAudioPlaying: boolean }) => {
                setIsAudioPlaying(data.isAudioPlaying);
            }
        );
        socket.on(
            'receive_update_overlay_index',
            (data: { overlayIndex: number }) => {
                setCurrentOverlayIndex(data.overlayIndex);
            }
        );
        socket.on(
            'receive_update_overlay_descriptions',
            (data: {
                overlayDescriptions: OverlayDescription[];
                overlayType: OverlayType;
            }) => {
                setCurrentOverlayDescriptions(
                    data.overlayDescriptions,
                    data.overlayType
                );
            }
        );
        socket.on(
            'receive_update_overlay_type',
            (data: { overlayType: OverlayType }) => {
                setCurrentOverlayType(data.overlayType);
            }
        );

        socket.on('receive_update_vehicle', ({ type }) => {
            setVehicle(type);
            setTimeout(() => {
                handleGoToGameStatus(GAMESTATUS.GAME1INTRODUCTION);
            }, 2000);
        });
        socket.on('receive_truck_objects_selected_game_two', (data) => {
            setSelectedTruckObject(data.deviceColor, data.object, data.isStrapped);
        });
        socket.on('receive_car_objects_selected_game_two', (data) => {
            setSelectedCarObject(data.color, data.carLocation, data.object);
        });
        socket.on('receive_validate_game_two', (data) => {
            // some stuff
            console.log(data);
        });

        socket.on('receive_update_unlock_screen', (data) => {
            setUnlockState(data.status);
        });

        socket.on('receive_update_start_engine', ({ status, target }) => {
            setTarget(target);
            setGameResult(status ? 'success' : 'error');
            setStartEngine(status);
        });

        socket.on('receive_update_cockpit_status', ({ action }) => {
            setCockpitStatus(action);
        });

        socket.on('receive_update_tire_pressure', ({ tire, pressure }) => {
            setTirePressure(tire, pressure);
        });

        socket.on(
            'receive_update_seat_belt_status',
            ({ status, gameResult, target }) => {
                setTarget(target);
                setGameResult(gameResult);
                setSeatBeltStatus(status);
            }
        );

        socket.on(
            'receive_validate_tire_pressure',
            ({ status, gameResult, target }) => {
                setTarget(target);
                setGameResult(gameResult);
                setTirePressureValidation(status);
            }
        );

        socket.on('receive_update_do_not_disturb', ({ status, gameResult }) => {
            setDoNotDisturb(status);
            setGameResult(gameResult);
        });

        socket.on('receive_update_receive_call', ({ status, target }) => {
            setTarget(target);
            setIsBeeingCalled(status);
        });

        socket.on('receive_reset_tire_pressure', () => {
            resetTirePressure();
            handleTirePressureValidation();
        });

        socket.on('receive_items_selected_game_four', (data) => {
            setSelectedSubstance(data.color, data.substance);
        });
        socket.on('receive_players_answer_game_five', (data) => {
            setPlayersAnswer(data.selectedReaction);
        });
        socket.on('receive_selected_situation_game_five', (data) => {
            setCurrentSituation(data.selectedSituation);
        });

        return () => {
            socket.off('receive_update_game_one_audio_job_status');
            socket.off('receive_game_status');
            socket.off('receive_device_activated_status');
            socket.off('receive_is_audio_playing');
            socket.off('receive_update_overlay_index');
            socket.off('receive_update_unlock_screen');
            socket.off('receive_update_vehicle');
            socket.off('receive_update_start_engine');
            socket.off('receive_update_seat_belt_status');
            socket.off('receive_validate_tire_pressure');
            socket.off('receive_update_do_not_disturb');
            socket.off('receive_reset');
            socket.off('receive_reset_tire_pressure');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const router = createBrowserRouter([
        {
            path: '/tv',
            element: room ? <Tv /> : <CreateRoom color='tv' setRoom={setRoom} />,
        },
        {
            path: '/red-tablet',
            element: room ? (
                <RedActionSwitch />
            ) : (
                <CreateRoom color='red' setRoom={setRoom} />
            ),
        },
        {
            path: '/blue-tablet',
            element: room ? (
                <BlueActionSwitch />
            ) : (
                <CreateRoom color='blue' setRoom={setRoom} />
            ),
        },
        {
            path: '/green-tablet',
            element: room ? (
                <GreenActionSwitch />
            ) : (
                <CreateRoom color='green' setRoom={setRoom} />
            ),
        },
        {
            path: '/orange-tablet',
            element: room ? (
                <OrangeActionSwitch />
            ) : (
                <CreateRoom color='orange' setRoom={setRoom} />
            ),
        },
        {
            path: '/mediator-tablet',
            element: room ? (
                <MediatorTablet />
            ) : (
                <CreateRoom color='mediator' setRoom={setRoom} />
            ),
        },
    ]);

    return <RouterProvider router={router} />;
}
