import styles from '@/features/devices/tablets/components/gameFive/fullScreenButton.module.scss';
import { handleSelectReaction } from '@/services/games/game5/gameFiveHandleFunctions';
import { motion } from 'framer-motion';
import type { Game5Tablets } from '@/types/games/gameFive/types';

export default function FullScreenButton({
    color,
    text,
}: {
    color: Game5Tablets;
    text: string;
}) {
    const onClick = () => {
        handleSelectReaction(color);
    };

    return (
        <motion.button
            onClick={onClick}
            whileTap={{ scale: 0.95 }}
            className={` ${styles[`${color}Container`]}`}
        >
            <p>{text}</p>
        </motion.button>
    );
}
