import createSelectors from '@/store/selectors';
import { deepCopy, INITIAL_GAME1_STORE } from '@/sessionInfo/sessionInfo';
import {
    type Game1Store,
    type Tire,
    type TirePressureStatus,
} from '@/types/games/gameOne/types';
import { create } from 'zustand';

const useGame1StoreBase = create<Game1Store>(() => deepCopy(INITIAL_GAME1_STORE));

export const useGame1Store = createSelectors(useGame1StoreBase);

export const setTirePressureValidation = (status: TirePressureStatus): void => {
    useGame1StoreBase.setState({ tirePressureInfoStatus: status });
};

export const setStartEngine = (status: boolean): void => {
    useGame1StoreBase.setState({ canStart: status });
};

export const setTirePressure = (tire: Tire, tirePressure: number): void => {
    useGame1StoreBase.setState((state) => ({
        ...state,
        tiresPressure: { ...state.tiresPressure, [tire]: tirePressure },
    }));
};

export const setSeatBeltStatus = (status: boolean): void => {
    useGame1StoreBase.setState({ isBeltClipped: status });
};

export const setDoNotDisturb = (status: boolean): void => {
    useGame1StoreBase.setState({ isDoNotDisturbOn: status });
};

export const setIsBeeingCalled = (status: boolean): void => {
    useGame1StoreBase.setState({ isBeingCalled: status });
};

export const setUnlockState = (status: boolean): void => {
    useGame1StoreBase.setState({ unlockState: status });
};

export const resetTirePressure = (): void => {
    useGame1StoreBase.setState((state) => ({
        ...state,
        tiresPressure: deepCopy(INITIAL_GAME1_STORE.tiresPressure),
    }));
};
