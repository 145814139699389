import { AstraInnov } from '@/assets/images/global';
import tabletStyle from '@/features/devices/tablets/components/screens/tabletScreen.module.scss';

export default function InactiveDevice() {
    return (
        <img
            src={AstraInnov}
            alt='Logo Astra'
            className={tabletStyle.astra_innov}
        />
    );
}
