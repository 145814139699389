import data from '@/data/gameFiveData.json';
import { getSituationBySymbol } from '@/services/games/game5/gameFiveUtils';
import { emitSocketEvent } from '@/services/global/globalUtils';
import type { Game5Tablets, Reaction } from '@/types/games/gameFive/types';

export function handleSelectReaction(deviceColor: Game5Tablets) {
    const selectedReaction = data.reactions[deviceColor] as Reaction;

    emitSocketEvent('send_players_answer_game_five', {
        selectedReaction,
    });
}

export function handleSelectSituation(symbol: string) {
    const selectedSituation = getSituationBySymbol(symbol);

    emitSocketEvent('send_selected_situation_game_five', {
        selectedSituation,
    });
}
