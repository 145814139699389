import createSelectors from '@/store/selectors';
import { deepCopy } from '@/sessionInfo/sessionInfo';
import {
    type CarLocation,
    INITIAL_GAME2_STATE,
    type CarObject,
    type Game2Store,
    type TruckObject,
} from '@/types/games/gameTwo/types';
import type { DeviceColor } from '@/types/global/types';
import { create } from 'zustand';

const useGame2StoreBase = create<Game2Store>(() => deepCopy(INITIAL_GAME2_STATE));

export const useGame2Store = createSelectors(useGame2StoreBase);

export const setSelectedTruckObject = (
    color: DeviceColor,
    truckObject: TruckObject,
    isStrapped: boolean
): void => {
    useGame2StoreBase.setState((state) => ({
        selectedTruckObjects: {
            ...state.selectedTruckObjects,
            [color]: {
                ...truckObject,
                isStrapped,
            },
        },
    }));
};

export const setSelectedCarObject = (
    color: DeviceColor,
    location: CarLocation,
    carObject: CarObject
): void => {
    useGame2StoreBase.setState((state) => ({
        selectedCarObjects: {
            ...state.selectedCarObjects,
            [color]: {
                ...state.selectedCarObjects[color],
                [location]: carObject,
            },
        },
    }));
};
