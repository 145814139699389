import AstraLoading from '@/features/components/astraLoading/AstraLoading';
import Cockpit from '@/features/components/cockpit/Cockpit';
import TirePressure from '@/features/components/gameOne/tirePressure/TirePressure';
import SelectCarObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectCarObjectButton';
import SelectTruckObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectTruckObjectButton';
import Keypad from '@/features/devices/tablets/components/gameFive/Keypad';
import TabletLayout from '@/features/devices/tablets/components/screens/TabletLayout';
import { useDynamicTabletName } from '@/hooks/useDynamicTabletName';
import { isCarSelected } from '@/services/global/globalUtils';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { GAMESTATUS } from '@/types/global/types';

export default function RedActionSwitch() {
    const { gameStatus, cockpitStatus } = useGameStore();
    const { red } = useDeviceStore.use.deviceStatus();

    useDynamicTabletName('red', red.label);

    const handleRender = () => {
        switch (gameStatus) {
            case GAMESTATUS.GAME1INTRODUCTION_LOADING:
                return <AstraLoading device='red' />;
            case GAMESTATUS.GAME1ACTION:
                return cockpitStatus === 'TIRE' ? (
                    <TirePressure tire='LeftFront' device='red' />
                ) : (
                    <Cockpit device='red' />
                );
            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.GAME2ACTION:
                return isCarSelected() ? (
                    <SelectCarObjectButton color='red' />
                ) : (
                    <SelectTruckObjectButton color='red' />
                );
            case GAMESTATUS.GAME5ACTION:
                return <Keypad />;
            case GAMESTATUS.GAME3ACTION:
            case GAMESTATUS.GAME4ACTION:
            case GAMESTATUS.FINISHED:
                return null;
        }
    };

    return <TabletLayout device={red}>{handleRender()}</TabletLayout>;
}
