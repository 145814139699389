import { MessagesIcon, PhoneIcon, SettingsIcon } from '@/assets/images/icons';
import CallHistory from '@/features/devices/tablets/orange/components/phone/callHistory/CallHistory';
import Clock from '@/features/devices/tablets/orange/components/phone/Clock';
import LockScreen from '@/features/devices/tablets/orange/components/phone/lockScreen/LockScreen';
import Messages from '@/features/devices/tablets/orange/components/phone/messages/Messages';
import Settings from '@/features/devices/tablets/orange/components/phone/settings/Settings';
import { useUnlockScreen } from '@/hooks/useUnlockScreen';
import { useState } from 'react';
import type { Nullable } from '@/types/global/types';
import styles from './phone.module.scss';

type MenuLabel = 'calls' | 'messages' | 'settings';

export default function Phone() {
    const isScreeUnlocked = useUnlockScreen();

    const [menuVisible, setMenuVisible] = useState<Nullable<MenuLabel>>(null);

    const handleOpenMenu = (menu: MenuLabel): void => {
        setMenuVisible(menu);
    };

    return (
        <div id='phone' className={styles.phone}>
            {!isScreeUnlocked ? (
                <LockScreen />
            ) : (
                <>
                    {!menuVisible && <Clock />}

                    {menuVisible === 'calls' && <CallHistory />}
                    {menuVisible === 'messages' && <Messages />}
                    {menuVisible === 'settings' && <Settings />}

                    <div className={styles.action_bar}>
                        <button onClick={() => handleOpenMenu('calls')}>
                            <img src={PhoneIcon} width={96} alt='appels' />
                        </button>
                        <button onClick={() => handleOpenMenu('messages')}>
                            <img src={MessagesIcon} width={96} alt='message' />
                        </button>

                        <button onClick={() => handleOpenMenu('settings')}>
                            <img src={SettingsIcon} width={96} alt='engrenage' />
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}
