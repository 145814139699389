import { TVPlaceholder } from '@/assets/images/global';
import styles from '@/features/components/gameFive/gameFive.module.scss';
import { useGame5Store } from '@/store/games/game5Store';

export default function GameFive() {
    const playersReaction = useGame5Store.use.playersAnswer();
    const currentSituation = useGame5Store.use.currentSituation();

    return (
        <div className={styles.container}>
            <img
                src={TVPlaceholder}
                alt='car placeholder for the tv'
                width={800}
                height={800}
            />
            <div>
                <p>Choix</p>
                <p>{playersReaction?.name}</p>
                <p>{currentSituation?.symbol}</p>
                <div></div>
            </div>
        </div>
    );
}
