import createSelectors from '@/store/selectors';
import { create } from 'zustand';
import { devtools, persist, type StorageValue } from 'zustand/middleware';

export interface DeviceState {
    roomName: string;
}

const useRoomStoreBase = create(
    devtools(
        persist(
            () => ({
                roomName: 'devRoom',
            }),
            {
                name: 'room-storage',
                storage: {
                    getItem: (key) => {
                        const item = localStorage.getItem(key);
                        return item
                            ? (JSON.parse(item) as StorageValue<{
                                  roomName: string;
                              }>)
                            : null;
                    },
                    setItem: (key, value) => {
                        const stringifiedValue = JSON.stringify(value);
                        localStorage.setItem(key, stringifiedValue);
                    },
                    removeItem: (key) => localStorage.removeItem(key),
                },
            }
        )
    )
);

export const useRoomStore = createSelectors(useRoomStoreBase);

export const setRoomName = (roomName: string): void => {
    useRoomStoreBase.setState({
        roomName,
    });
};
