import { Astra } from '@/assets/images/global';
import { SeatBeltIcon, TirePressureIcon } from '@/assets/images/icons';
import data from '@/data/global.json';
import ProgressBar from '@/features/devices/tablets/components/progressBar/ProgressBar';
import {
    handleSeatBeltFastening,
    handleTirePressureValidation,
} from '@/services/global/globalHandleFunctions';
import { useGame1Store } from '@/store/games/game1Store';
import styles from './carStatus.module.scss';

export default function CarStatus() {
    const isSeatBeltFasten = useGame1Store.use.isBeltClipped();
    const completed = 0;
    const conformityRate = 0;

    const checkSeatBelt = () => {
        handleSeatBeltFastening(isSeatBeltFasten);
    };

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.check}>
                    <img src={Astra} alt='astra' width={96} />
                    <p>{data.cockpit.conformityRate}</p>
                    <div>
                        <ProgressBar numberOfItemCompleted={completed} />
                        <p>{conformityRate}%</p>
                    </div>
                </div>
                <div className={styles.check}>
                    <img
                        src={TirePressureIcon}
                        alt='mauvaise pression des pneus'
                        width={48}
                    />
                    <p>{data.cockpit.checkTirePressure}</p>
                    <button
                        className={styles.button}
                        type='button'
                        onClick={handleTirePressureValidation}
                    >
                        {data.cockpit.check}
                    </button>
                </div>

                <div className={styles.check}>
                    <img src={SeatBeltIcon} alt='ceinture détachée' width={48} />
                    <p>{data.cockpit.checkSeatBelt}</p>
                    <button
                        className={styles.button}
                        type='button'
                        onClick={checkSeatBelt}
                    >
                        {data.cockpit.check}
                    </button>
                </div>
            </div>
        </div>
    );
}
