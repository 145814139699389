import { useState } from 'react';
import styles from './speedometer.module.scss';

export default function Speedometer() {
    const [speed, setSpeed] = useState(0);
    const limitation = 0;

    return (
        <div>
            <div className={styles.speedometer}>
                <div></div>
                <div className={styles.speed}>
                    <h1>{speed}</h1>&nbsp;km/h
                </div>
                <div>
                    <div className={styles.limitation}> {limitation}</div>
                </div>
            </div>
        </div>
    );
}
