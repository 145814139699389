import globalData from '@/data/global.json';
import AstraLoading from '@/features/components/astraLoading/AstraLoading';
import SeatBelt from '@/features/components/gameOne/seatBelt/SeatBelt';
import TirePressure from '@/features/components/gameOne/tirePressure/TirePressure';
import ActionButton from '@/features/devices/tablets/components/buttons/ActionButton';
import SelectCarObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectCarObjectButton';
import SelectTruckObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectTruckObjectButton';
import FullScreenButton from '@/features/devices/tablets/components/gameFive/FullScreenButton';
import TabletLayout from '@/features/devices/tablets/components/screens/TabletLayout';
import { useDynamicTabletName } from '@/hooks/useDynamicTabletName';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { isCarSelected } from '@/services/global/globalUtils';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { GAMESTATUS } from '@/types/global/types';

export default function GreenActionSwitch() {
    const { gameStatus, cockpitStatus } = useGameStore();
    const { green } = useDeviceStore.use.deviceStatus();

    useDynamicTabletName('green', green.label);
    useReSynchronizeDevice('green');

    const handleRender = () => {
        switch (gameStatus) {
            case GAMESTATUS.GAME1INTRODUCTION_LOADING:
                return <AstraLoading device='green' />;
            case GAMESTATUS.GAME1INTRODUCTION:
                return (
                    <ActionButton
                        action='goToNextOverlay'
                        title={globalData.global.next}
                        color='black'
                    />
                );
            case GAMESTATUS.GAME1ACTION:
                return cockpitStatus === 'TIRE' ? (
                    <TirePressure tire='LeftRear' />
                ) : (
                    <SeatBelt />
                );
            case GAMESTATUS.GAME2ACTION:
                return isCarSelected() ? (
                    <SelectCarObjectButton color='green' />
                ) : (
                    <SelectTruckObjectButton color='green' />
                );
            case GAMESTATUS.GAME5ACTION:
                return <FullScreenButton color='green' text='Securite' />;
            case GAMESTATUS.GAME3ACTION:
            case GAMESTATUS.GAME4ACTION:
            case GAMESTATUS.WELCOME_SCREEN:
            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.FINISHED:
                return null;
        }
    };

    return <TabletLayout device={green}>{handleRender()}</TabletLayout>;
}
