import { Trickyberpunk } from '@/assets/images/global';
import styles from '@/features/devices/tablets/components/buttons/gameTwo/selectObjectButton.module.scss';
import useVehicleObject from '@/hooks/useVehicleObject';
import {
    handleCarObjectSelectedGameTwo,
    handleTruckObjectSelectedGametwo,
} from '@/services/games/game2/gameTwoHandleFunctions';
import {
    assertIsCarObject,
    assertIsTruckObject,
} from '@/services/games/game2/gameTwoUtils';
import { useGame2Store } from '@/store/games/game2Store';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import type {
    CarLocation,
    CarObject,
    TruckObject,
} from '@/types/games/gameTwo/types';
import { type DeviceColor, type Maybe, type Nullable } from '@/types/global/types';

type CarProps = {
    type: 'car';
    color: DeviceColor;
    carLocation: CarLocation;
    disabled?: false;
};

type TruckProps = {
    type: 'truck';
    disabled: boolean;
    color: DeviceColor;
};

type SelectObjectButtonProps = CarProps | TruckProps;

export default function SelectObjectButton(props: SelectObjectButtonProps) {
    const { type, color } = props;

    const [currentVehicleObject, setCurrentVehicleObject] =
        useState<Maybe<Nullable<CarObject | TruckObject>>>(null);

    const { selectedCarObjects, selectedTruckObjects } = useGame2Store();

    useEffect(() => {
        const currentObj =
            type === 'car'
                ? selectedCarObjects[color][props.carLocation]
                : selectedTruckObjects[color];

        setCurrentVehicleObject(currentObj);
    }, []);

    const nextVehicleObject = useVehicleObject(currentVehicleObject?.id);

    const handleCarButtonClick = (carLocation: CarLocation) => {
        if (type === 'truck') return;
        if (!nextVehicleObject) return;

        assertIsCarObject(nextVehicleObject);

        handleCarObjectSelectedGameTwo(carLocation, nextVehicleObject, color);
        setCurrentVehicleObject(nextVehicleObject);
    };

    const handleTruckButtonClick = () => {
        if (type === 'car') return;
        if (!nextVehicleObject) return;

        assertIsTruckObject(nextVehicleObject);

        handleTruckObjectSelectedGametwo(nextVehicleObject, color, false);
        setCurrentVehicleObject(nextVehicleObject);
    };

    const onButtonClick = () => {
        if (type === 'car') {
            handleCarButtonClick(props.carLocation);
        } else {
            handleTruckButtonClick();
        }
    };

    return (
        <div className={styles.container}>
            <p>{currentVehicleObject?.name}</p>
            <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={onButtonClick}
                disabled={props.disabled}
            >
                {currentVehicleObject?.image ? (
                    <img
                        src={currentVehicleObject.image}
                        alt='object'
                        width={150}
                        height={150}
                    />
                ) : (
                    <img
                        src={Trickyberpunk}
                        alt='gif logo tricky'
                        width={150}
                        height={150}
                        style={{
                            width: '150px',
                            height: '150px',
                            border: '1px solid black',
                        }}
                    />
                )}
            </motion.button>
        </div>
    );
}
