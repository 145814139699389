import dateGameFour from '@/data/gameFourData.json';
import Button from '@/features/devices/tablets/components/buttons/Button';
import styles from '@/features/devices/tablets/components/gameFour/selectSubstance.module.scss';
import { handleItemsSelectedGameFour } from '@/services/games/game4/gameFourHandleFunctions';
import { mapTabletColorToName } from '@/services/global/globalUtils';
import { useGame4Store } from '@/store/games/game4Store';
import { motion } from 'framer-motion';
import type { Game4Tablets, Substance } from '@/types/games/gameFour/types';
import data from '@/data/gameFourData.json';

export default function SelectSubstance({ color }: { color: Game4Tablets }) {
    const substances = dateGameFour.substances;
    const selectedSubstance = useGame4Store.use.itemsSelected()[color];
    const colorToDisplay = mapTabletColorToName(color);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <p>
                    {data.texts.vehicle} {colorToDisplay}
                </p>
                <p>{data.texts.description}</p>
            </div>

            <div className={styles.imageContainer}></div>

            <div>
                {substances.map((substance) => {
                    const isSelected = selectedSubstance?.id === substance.id;

                    return (
                        <SubstanceCard
                            key={substance.name}
                            substance={substance}
                            isSelected={isSelected}
                            color={color}
                        />
                    );
                })}
            </div>
            <Button
                color='black'
                text='Valider ma selection'
                onClick={() => {
                    console.log('Valider ma selection');
                }}
                additionalStyle={{
                    width: '90%',
                }}
            />
        </div>
    );
}

function SubstanceCard({
    substance,
    isSelected,
    color,
}: {
    substance: Substance;
    isSelected: boolean;
    color: Game4Tablets;
}) {
    return (
        <motion.button
            className={
                styles[`${'substanceContainer'}${isSelected ? 'Selected' : ''}`]
            }
            whileTap={{
                scale: 0.9,
            }}
            onClick={() => {
                handleItemsSelectedGameFour(color, substance);
            }}
        >
            <div
                className={
                    styles[
                        `${'substanceIconContainer'}${substance.slug.charAt(0).toUpperCase() + substance.slug.slice(1)}`
                    ]
                }
            >
                <img src={substance.image} alt='substance icon' />
            </div>
            <p>{substance.name}</p>
        </motion.button>
    );
}
