import { useGameStore } from '@/store/gameStore';
import { useEffect, useState } from 'react';
import { type Vehicle } from '@/types/games/types';
import { type Nullable } from '@/types/global/types';

export const useVehicleChange = (): boolean => {
    const selectedVehicle = useGameStore.use.vehicle();
    const [vehicle, setVehicle] = useState<Nullable<Vehicle>>(selectedVehicle);
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        if (vehicle !== selectedVehicle) {
            setVehicle(selectedVehicle);
            setHasChanged(true);
        }
        const timeoutId = setTimeout(() => {
            setHasChanged(false);
        }, 2000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [vehicle, selectedVehicle]);

    return hasChanged;
};
