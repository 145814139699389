import InfoManager from '@/features/components/infoManager/InfoManager';
import InactiveDevice from '@/features/devices/tablets/components/InactiveDevice';
import tabletStyle from '@/features/devices/tablets/components/screens/tabletScreen.module.scss';

import { useGameStore } from '@/store/gameStore';
import type { PropsWithChildren } from 'react';
import type { DeviceStatus } from '@/types/games/types';

interface TabletLayoutProps extends PropsWithChildren {
    device: DeviceStatus;
}

export default function TabletLayout({ device, children }: TabletLayoutProps) {
    const gameResult = useGameStore.use.gameResult();
    const isTabletActive = device.isActivated;

    return (
        <div
            className={
                isTabletActive
                    ? tabletStyle.redTabletContainer
                    : tabletStyle.neutralTabletContainer
            }
        >
            {gameResult !== '' && <InfoManager gameResult={gameResult} />}
            {isTabletActive ? children : <InactiveDevice />}
        </div>
    );
}
