import createSelectors from '@/store/selectors';
import { deepCopy, INITIAL_GAME4_STORE } from '@/sessionInfo/sessionInfo';
import type {
    Game4Store,
    Game4Tablets,
    Substance,
} from '@/types/games/gameFour/types';
import { create } from 'zustand';

const useGame4StoreBase = create<Game4Store>(() => deepCopy(INITIAL_GAME4_STORE));

export const useGame4Store = createSelectors(useGame4StoreBase);

export function setSelectedSubstance(
    color: Game4Tablets,
    substance: Substance
): void {
    useGame4StoreBase.setState((state) => ({
        itemsSelected: {
            ...state.itemsSelected,
            [color]: substance,
        },
    }));
}
