import createSelectors from '@/store/selectors';
import { deepCopy, INITIAL_GAME_STORE } from '@/sessionInfo/sessionInfo';
import type {
    CockpitAction,
    GameResult,
    GameStore,
    Target,
    Vehicle,
} from '@/types/games/types';
import { type GameStatus } from '@/types/global/types';
import { create } from 'zustand';

const useGameStoreBase = create<GameStore>(() => deepCopy(INITIAL_GAME_STORE));

export const useGameStore = createSelectors(useGameStoreBase);

export const setGameStatus = (gameStatus: GameStatus): void => {
    useGameStoreBase.setState({
        gameStatus,
    });
};

export const setIsAudioPlaying = (isAudioPlaying: boolean): void => {
    useGameStoreBase.setState({
        isAudioPlaying,
    });
};

export const setGameResult = (gameResult: GameResult): void => {
    useGameStoreBase.setState({
        gameResult,
    });
};

export const setTarget = (target: Target): void => {
    useGameStoreBase.setState({ target });
};

export const setVehicle = (vehicle: Vehicle): void => {
    useGameStoreBase.setState({ vehicle });
};

export const setCockpitStatus = (action: CockpitAction): void => {
    useGameStoreBase.setState({ cockpitStatus: action });
};
