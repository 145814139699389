import {
    type GameStatus,
    type Nullable,
    type DeviceColorWithTvAndMediator,
} from '@/types/global/types';

export type Vehicle = 'car' | 'truck';

export type CockpitAction = 'TIRE' | 'ETA' | 'VIEW' | 'MAP' | 'COCKPIT';

export type GameResult = 'success' | 'error' | '';
export type Target = '' | 'SEATBELT' | 'TIRE' | 'PHONE' | 'ENGINE';

export interface GameStore {
    roomName: string;
    gameStatus: GameStatus;
    isAudioPlaying: boolean;
    vehicle: Nullable<Vehicle>;
    cockpitStatus: CockpitAction;
    gameResult: GameResult;
    target: Target;
}
export interface DeviceStore {
    deviceStatus: Record<DeviceColorWithTvAndMediator, DeviceStatus>;
}

export interface DeviceStatus {
    isConnected: boolean;
    isActivated: boolean;
    label: string;
    image?: string;
}

export interface OverlayStore {
    currentOverlayIndex: number;
    overlayDescriptions: OverlayDescription[];
    overlayType: OverlayType;
}

export enum OverlayType {
    NEUTRAL = 'neutral',
    SUCCESS = 'success',
    ERROR = 'error',
    UNLOCK = 'unlock',
}

export interface OverlayDescription {
    id: number;
    title: string;
    text: string;
    audio: string;
}
