import { motion } from 'framer-motion';

import styles from '@/features/devices/tablets/components/progressBar/progressBar.module.scss';

type ProgressBarProps = {
    title?: string;
    numberOfItemCompleted: number;
    totalNumberOfItems?: number;
};

export default function ProgressBar({
    title,
    numberOfItemCompleted = 0,
    totalNumberOfItems = 1,
}: ProgressBarProps) {
    const progress = (numberOfItemCompleted / totalNumberOfItems) * 100;

    return (
        <div className={styles.title}>
            {title && (
                <div>
                    <h2>{title}</h2>
                </div>
            )}
            <div className={styles.bar}>
                <motion.div
                    className={styles.progress}
                    initial={{ width: 0 }}
                    animate={{ width: `${progress}%` }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                />
            </div>
        </div>
    );
}
