import overlayContent from '@/data/overlayDeviceContent.json';
import gameOneData from '@/data/gameOneData.json';
import {
    emitSocketEvent,
    getNextGameStatus,
    tabletStatusGameConfig,
} from '@/services/global/globalUtils';
import { useOverlayStore } from '@/store/overlayStore';

import {
    OverlayType,
    type CockpitAction,
    type GameResult,
    type OverlayDescription,
    type Vehicle,
} from '@/types/games/types';
import {
    GAMESTATUS,
    type DeviceColorWithTvAndMediator,
    type GameStatus,
} from '@/types/global/types';
import { useGame1Store } from '@/store/games/game1Store';
import { useGameStore } from '@/store/gameStore';
import type { Tire, TirePressureStatus } from '@/types/games/gameOne/types';

export const handleReset = (): void => {
    emitSocketEvent('send_reset', {});
};

export const handleSendGameStatus = (gameStatus: GameStatus): void => {
    emitSocketEvent('send_game_status', { status: gameStatus });
};

export const handleGameResult = (result: GameResult): void => {
    emitSocketEvent('send_game_result', { result });
};

export const handleUpdateDeviceActivatedStatus = (
    deviceColor: DeviceColorWithTvAndMediator,
    status: boolean
): void => {
    emitSocketEvent('send_device_activated_status', {
        deviceColor,
        status,
    });
};

export const handleSendIsAudioPlaying = (isAudioPlaying: boolean): void => {
    const responsesSocket = {
        isAudioPlaying: isAudioPlaying,
    };
    emitSocketEvent('send_is_audio_playing', responsesSocket);
};

export const handleTabletStatus = (deviceActivationStatus: {
    [color: string]: boolean;
}): void => {
    Object.entries(deviceActivationStatus).forEach(([color, status]) => {
        handleUpdateDeviceActivatedStatus(
            color as DeviceColorWithTvAndMediator,
            status
        );
    });
};

export const handleGoToGameStatus = (gameStatus: GameStatus): void => {
    handleSendGameStatus(gameStatus);
    handleTabletStatus(
        tabletStatusGameConfig[gameStatus as keyof typeof tabletStatusGameConfig]
    );

    const isGameAction =
        gameStatus === GAMESTATUS.GAME1ACTION ||
        gameStatus === GAMESTATUS.GAME2ACTION ||
        gameStatus === GAMESTATUS.GAME3ACTION ||
        gameStatus === GAMESTATUS.GAME4ACTION ||
        gameStatus === GAMESTATUS.GAME5ACTION;

    if (isGameAction) {
        handleUpdateOverlayDescriptions([], OverlayType.NEUTRAL);
    } else {
        handleUpdateOverlayDescriptions(
            overlayContent[gameStatus].tv,
            OverlayType.NEUTRAL
        );
    }
};

export const handleUpdateOverlayIndex = (overlayIndex: number): void => {
    emitSocketEvent('send_update_overlay_index', { overlayIndex });
};

export const handleUpdateOverlayType = (overlayType: OverlayType): void => {
    emitSocketEvent('send_update_overlay_type', { overlayType });
};

export const handleUpdateOverlayDescriptions = (
    overlayDescriptions: OverlayDescription[],
    overlayType: OverlayType
): void => {
    emitSocketEvent('send_update_overlay_descriptions', {
        overlayDescriptions,
        overlayType,
    });
};

export const handleNextOrPreviousOverlayDescription = (
    action: 'next' | 'previous'
): void => {
    const nextGameStatus = getNextGameStatus();
    const { overlayDescriptions, currentOverlayIndex } = useOverlayStore.getState();

    if (action === 'next') {
        const isLastOverlayMessage =
            overlayDescriptions &&
            currentOverlayIndex === overlayDescriptions.length - 1;

        if (isLastOverlayMessage) {
            handleGoToGameStatus(nextGameStatus);
        } else {
            handleUpdateOverlayIndex(currentOverlayIndex + 1);
        }
    } else if (action === 'previous') {
        const isFirstMessage = overlayDescriptions && currentOverlayIndex === 0;
        if (!isFirstMessage) handleUpdateOverlayIndex(currentOverlayIndex - 1);
    }
};

export const playSound = (audio: string): object => {
    const sound = new Audio(audio);
    return sound.play();
};

export function handleVehicleSelection(type: Vehicle): void {
    emitSocketEvent('send_update_vehicle', { type });
}

export const handleCockpitAction = (action: CockpitAction): void => {
    emitSocketEvent('send_update_cockpit_status', { action });
};

export const handleTirePressureUpdate = (tire: Tire, pressure: number): void => {
    emitSocketEvent('send_update_tire_pressure', { tire, pressure });
};

export const handleTirePressureValidation = (): void => {
    const vehicle = useGameStore.getState().vehicle;
    const currentTiresPressure = useGame1Store.getState().tiresPressure;

    if (!vehicle) return;

    const expectedValue = gameOneData[`${vehicle}TirePressure`];
    const pressureStatus = getPressureStatus(currentTiresPressure, expectedValue);

    const result = pressureStatus === 'good' ? 'success' : 'error';

    emitSocketEvent('send_validate_tire_pressure', {
        status: pressureStatus,
        gameResult: result,
        target: 'TIRE',
    });
};

export const handleUnlockScreen = (status: boolean): void => {
    emitSocketEvent('send_update_unlock_screen', { status });
};

export const handleResetTirePressure = (): void => {
    emitSocketEvent('send_reset_tire_pressure', {});
};

export const handleSeatBeltFastening = (status: boolean): void => {
    emitSocketEvent('send_update_seat_belt_status', {
        status,
        gameResult: status ? 'success' : 'error',
        target: 'SEATBELT',
    });
};

export const handleResetSeatBelt = (): void => {
    emitSocketEvent('send_reset_seat_belt_status', {});
};

export const handleSetDoNotDisturb = (status: boolean): void => {
    emitSocketEvent('send_update_do_not_disturb', {
        status,
        gameResult: status ? 'success' : 'error',
    });
};

export const handleReceiveCall = (status: boolean): void => {
    emitSocketEvent('send_update_receive_call', { status, target: 'PHONE' });
};

export const handleStartEngine = (): void => {
    const { isBeltClipped, isDoNotDisturbOn, tirePressureInfoStatus } =
        useGame1Store.getState();

    const isStartable =
        isBeltClipped && isDoNotDisturbOn && tirePressureInfoStatus === 'good';

    emitSocketEvent('send_update_start_engine', {
        status: isStartable,
        target: 'ENGINE',
    });
};

function getPressureStatus(
    tires: Record<Tire, number>,
    control: number
): TirePressureStatus {
    let status: TirePressureStatus = 'good';

    for (const current in tires) {
        const currentPressure = parseFloat(tires[current as Tire].toFixed(2));

        if (currentPressure === control) continue;

        status = currentPressure > control ? 'over' : 'under';
    }
    return status;
}
