import overlayData from '@/data/overlayDeviceContent.json';
import globalData from '@/data/global.json';
import styles from './astraLoading.module.scss';
import ProgressBar from '@/features/devices/tablets/components/progressBar/ProgressBar';
import { useGameStore } from '@/store/gameStore';
import { useEffect, useState } from 'react';
import { GAMESTATUS, type DeviceColorWithTV } from '@/types/global/types';
import { handleGoToGameStatus } from '@/services/global/globalHandleFunctions';
import { Astra } from '@/assets/images/global';

interface AstraLoadingProps {
    device: DeviceColorWithTV;
}

export default function AstraLoading({ device }: AstraLoadingProps) {
    const [progress, setProgress] = useState(0);
    const gameStatus = useGameStore.use.gameStatus();
    const isTV = device === 'tv';

    const { title, text } =
        overlayData[gameStatus as keyof typeof overlayData]['tv'][0];

    useEffect(() => {
        let timeoutId = null;
        if (progress < 100) {
            timeoutId = setTimeout(() => {
                setProgress((previous) => previous + 20);
            }, 500);
        }

        if (progress === 100) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                handleGoToGameStatus(GAMESTATUS.GAME1INTRODUCTION);
            }, 1000);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [progress]);

    return (
        <div
            className={`${styles.container} ${!isTV ? styles.justify_center : null}`}
        >
            {isTV && (
                <div className={styles.text}>
                    <h3>{title}</h3>
                    <h1>{globalData.global.pleaseWait.toUpperCase()}</h1>
                    <h5>{text}...</h5>
                </div>
            )}
            <div className={styles.progressbar}>
                <ProgressBar
                    title={`${progress} %`}
                    numberOfItemCompleted={progress}
                    totalNumberOfItems={100}
                />
            </div>
            {isTV && <img src={Astra} alt='astra' width={80} />}
        </div>
    );
}
