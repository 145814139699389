import globalData from '@/data/global.json';
import AstraLoading from '@/features/components/astraLoading/AstraLoading';
import TirePressure from '@/features/components/gameOne/tirePressure/TirePressure';
import SelectVehicle from '@/features/components/vehicleSelection/SelectVehicle';
import ActionButton from '@/features/devices/tablets/components/buttons/ActionButton';
import Button from '@/features/devices/tablets/components/buttons/Button';
import SelectCarObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectCarObjectButton';
import SelectTruckObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectTruckObjectButton';
import FullScreenButton from '@/features/devices/tablets/components/gameFive/FullScreenButton';
import SelectSubstance from '@/features/devices/tablets/components/gameFour/SelectSubstance';
import TabletLayout from '@/features/devices/tablets/components/screens/TabletLayout';
import Phone from '@/features/devices/tablets/orange/components/phone';
import CallEntry from '@/features/devices/tablets/orange/components/phone/callEntry/CallEntry';
import { useDynamicTabletName } from '@/hooks/useDynamicTabletName';
import { useReceiveCall } from '@/hooks/useReceiveCall';
import { handleGoToGameStatus } from '@/services/global/globalHandleFunctions';
import { isCarSelected } from '@/services/global/globalUtils';
import { useDeviceStore } from '@/store/deviceStore';
import { useGame1Store } from '@/store/games/game1Store';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import { GAMESTATUS } from '@/types/global/types';

export default function OrangeActionSwitch() {
    const { gameStatus, cockpitStatus } = useGameStore();
    const { orange } = useDeviceStore.use.deviceStatus();
    const overlayIdx = useOverlayStore.use.currentOverlayIndex();
    const isBeingCalled = useGame1Store.use.isBeingCalled();
    const canGoToVehicleSelection =
        overlayIdx === 0 && gameStatus === 'GAME1INTRODUCTION';

    useDynamicTabletName('orange', orange.label);
    useReceiveCall();

    const handleGoToVehicleSelection = (): void => {
        handleGoToGameStatus(GAMESTATUS.VEHICLE_SELECTION);
    };

    const renderPhone = () => {
        return isBeingCalled ? <CallEntry /> : <Phone />;
    };

    const renderGameOne = () => {
        return cockpitStatus === 'TIRE' ? (
            <TirePressure tire='RightFront' />
        ) : (
            renderPhone()
        );
    };

    const handleRender = () => {
        switch (gameStatus) {
            case GAMESTATUS.WELCOME_SCREEN:
                return (
                    <Button
                        text={globalData.global.play}
                        onClick={handleGoToVehicleSelection}
                        color='black'
                    />
                );
            case GAMESTATUS.VEHICLE_SELECTION:
                return <SelectVehicle type='car' />;
            case GAMESTATUS.GAME1INTRODUCTION_LOADING:
                return <AstraLoading device='orange' />;
            case GAMESTATUS.GAME1INTRODUCTION:
                return (
                    <div>
                        <ActionButton
                            action='goToNextOverlay'
                            title={globalData.global.next}
                            color='black'
                        />
                        {canGoToVehicleSelection && (
                            <ActionButton
                                action='goToVehicleSelectionStatus'
                                title={globalData.global.previous}
                                color='white'
                            />
                        )}
                    </div>
                );
            case GAMESTATUS.GAME1ACTION:
                return renderGameOne();
            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.GAME2ACTION:
                return isCarSelected() ? (
                    <SelectCarObjectButton color='orange' />
                ) : (
                    <SelectTruckObjectButton color='orange' />
                );
            case GAMESTATUS.GAME4ACTION:
                return <SelectSubstance color='orange' />;
            case GAMESTATUS.GAME5ACTION:
                return <FullScreenButton color='orange' text='Reglementation' />;
            case GAMESTATUS.FINISHED:
                return null;
        }
    };

    return <TabletLayout device={orange}>{handleRender()}</TabletLayout>;
}
