import { carObjects, truckObjects } from '@/data/gameTwoData.json';
import { isCarSelected } from '@/services/global/globalUtils';
import { useGame2Store } from '@/store/games/game2Store';
import type { CarObject, TruckObject } from '@/types/games/gameTwo/types';
import type { Maybe, Nullable } from '@/types/global/types';

export default function useVehicleObject<TVehicle extends TruckObject | CarObject>(
    currentVehicleObjectId?: number
): TVehicle {
    const isCar = isCarSelected();

    const { selectedCarObjects, selectedTruckObjects } = useGame2Store();

    function selectVehicleObjects<
        TObj extends Array<Nullable<CarObject>> | typeof selectedTruckObjects,
    >(obj: TObj): Maybe<number>[] {
        return Object.values(obj).map((vehicleObject) => vehicleObject?.id);
    }

    const selectedVehicleObjects = isCar
        ? Object.values(selectedCarObjects)
              .map((location) => Object.values(location))
              .flat()
        : selectedTruckObjects;

    const vehicleObjects = isCar ? carObjects : truckObjects;

    const selectableVehicleObjects = selectVehicleObjects(selectedVehicleObjects);

    const currentSelectableVehicleObjects = vehicleObjects.filter(
        (vehicleObject) => {
            return !selectableVehicleObjects.includes(vehicleObject.id);
        }
    );

    if (
        !currentVehicleObjectId ||
        currentVehicleObjectId > currentSelectableVehicleObjects?.at(-1)!.id
    )
        return currentSelectableVehicleObjects[0] as TVehicle;

    const currentItemIndex = currentSelectableVehicleObjects.findIndex(
        (vehicleObject) => vehicleObject.id > currentVehicleObjectId
    );

    return currentSelectableVehicleObjects[currentItemIndex] as TVehicle;
}
