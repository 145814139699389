import { useFastenSeatBelt } from '@/hooks/useFastenSeatBelt';
import styles from './seatBelt.module.scss';
import { Belt, Latche, Tongue } from '@/assets/images/global';

export default function SeatBelt() {
    useFastenSeatBelt();

    return (
        <div id='drag_container' className={styles.drag_area}>
            <img id='belt' src={Belt} className={styles.belt} alt='ceinture' />
            <img id='tongue' src={Tongue} className={styles.tongue} alt='boucle' />
            <img id='latche' src={Latche} className={styles.latch} alt='clip' />
        </div>
    );
}
