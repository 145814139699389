import { Boop } from '@/assets/sounds/soundsEffects';
import styles from '@/features/devices/tablets/components/buttons/actionButton.module.scss';

import Button, {
    type ButtonColor,
} from '@/features/devices/tablets/components/buttons/Button';
import {
    handleGoToGameStatus,
    handleNextOrPreviousOverlayDescription,
} from '@/services/global/globalHandleFunctions';
import { getNextGameStatus } from '@/services/global/globalUtils';
import { GAMESTATUS } from '@/types/global/types';

type ActionType =
    | 'startGame'
    | 'goToNextOverlay'
    | 'goToVehicleSelectionStatus'
    | 'goToNextGameStatus'
    | 'goToNextPreventOverlay';

type ActionHandlers = {
    [K in ActionType]: () => void;
};

interface ActionButtonProps {
    title: string;
    action: ActionType;
    style?: React.CSSProperties;
    color: ButtonColor;
    soundPath?: string;
}

export default function ActionButton({
    title,
    action,
    style,
    color,
    soundPath,
}: ActionButtonProps) {
    const actionHandlers: ActionHandlers = {
        startGame: () => handleGoToGameStatus(GAMESTATUS.GAME1INTRODUCTION),
        goToNextOverlay: () => handleNextOrPreviousOverlayDescription('next'),
        goToVehicleSelectionStatus: () =>
            handleGoToGameStatus(GAMESTATUS.VEHICLE_SELECTION),
        goToNextGameStatus: () => handleGoToGameStatus(getNextGameStatus()),
        goToNextPreventOverlay: () =>
            handleNextOrPreviousOverlayDescription('next'),
    };

    return (
        <div className={styles.buttonContainer} style={style}>
            <Button
                text={title}
                onClick={() => actionHandlers[action]()}
                soundPath={soundPath ?? Boop}
                color={color}
            />
        </div>
    );
}
