import styles from '@/features/components/text/textAnimation.module.scss';

export default function TextAnimation({ text }: { text: string }) {
    const randomNumber = Math.floor(Math.random() * 100);

    return (
        <p key={text}>
            {text?.split('').map((letter: string, i: number) => (
                <span
                    key={`${letter}${i}${randomNumber}`}
                    className={styles[`fadeInText-${i}`]}
                >
                    {letter}
                </span>
            ))}
        </p>
    );
}
