import { EngineStart } from '@/assets/images/global';
import { handleStartEngine } from '@/services/global/globalHandleFunctions';
import { motion } from 'framer-motion';
import styles from './engineStartButton.module.scss';

export default function EngineStartButton() {
    return (
        <div className={styles.container}>
            <motion.button whileTap={{ scale: 0.98 }} onClick={handleStartEngine}>
                <img src={EngineStart} alt='Boutton démarrage moteur' width={512} />
            </motion.button>
        </div>
    );
}
