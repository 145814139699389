import { handleUnlockScreen } from '@/services/global/globalHandleFunctions';
import { useGame1Store } from '@/store/games/game1Store';
import { useEffect, useState } from 'react';

const DURATION_TO_UNLOCK = 2500;

export const useUnlockScreen = (): boolean => {
    const unlockState = useGame1Store.getState().unlockState;
    const [startPressingAt, setStartPressingAt] = useState(0);

    useEffect(() => {
        window.oncontextmenu = () => false;
        const unlockElement = document.getElementById('unlock');
        if (!unlockElement) return;

        const startCounting = () => {
            setStartPressingAt(new Date().getTime());
        };

        const resetCounting = () => {
            const now = new Date().getTime();

            if (now - startPressingAt >= DURATION_TO_UNLOCK) {
                handleUnlockScreen(true);
            }
            setStartPressingAt(0);
        };

        unlockElement.addEventListener('touchstart', startCounting);
        unlockElement.addEventListener('mousedown', startCounting);
        unlockElement.addEventListener('touchend', resetCounting);
        unlockElement.addEventListener('mouseup', resetCounting);

        return () => {
            unlockElement.removeEventListener('touchstart', startCounting);
            unlockElement.removeEventListener('mousedown', startCounting);
            unlockElement.removeEventListener('touchend', resetCounting);
            unlockElement.removeEventListener('mouseup', resetCounting);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return unlockState;
};
