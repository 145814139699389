import { EmployeHead, GouvHead, OuvrierHead } from '@/assets/images/characters';
import styles from '@/features/devices/tablets/orange/components/phone/phone.module.scss';
import gameOneData from '@/data/gameOneData.json';

const ICONS_MAP = {
    employeHead: EmployeHead,
    gouvHead: GouvHead,
    ouvrierHead: OuvrierHead,
} as const;

type IconsMapKey = keyof typeof ICONS_MAP;

export default function CallHistory() {
    const calls = gameOneData.callerPool;

    return (
        <div className={styles.list}>
            <h3>Appels</h3>

            {calls.map((call) => (
                <div className={styles.row} key={call.id}>
                    <img
                        src={ICONS_MAP[call.icon as IconsMapKey]}
                        width={96}
                        alt='avatar'
                    />
                    <p>{call.caller}</p>
                    <p>{call.device}</p>
                    <p>{call.time}</p>
                </div>
            ))}
        </div>
    );
}
