import { situations } from '@/data/gameFiveData.json';
import type { Situation } from '@/types/games/gameFive/types';

export function getSymbolSituations() {
    return situations.map((situation) => situation.symbol);
}

export function getSituationBySymbol(symbol: string): Situation {
    return situations.find((situation) => situation.symbol === symbol)!;
}
