import globalStyle from '@/assets/styles/app.module.scss';
import MediatorScreen from '@/features/devices/tablets/mediator/MediatorScreen';
import PreConnectionMediatorScreen from '@/features/devices/tablets/mediator/PreConnectionMediatorScreen';
import { useDynamicTabletName } from '@/hooks/useDynamicTabletName';
import useReconnection from '@/hooks/useReconnection';
import { emitSocketEvent } from '@/services/global/globalUtils';

import { useDeviceStore } from '@/store/deviceStore';

export default function MediatorTablet() {
    const { mediator, blue, orange, red, green } =
        useDeviceStore.use.deviceStatus();
    useReconnection();
    useDynamicTabletName('mediator', mediator.label);

    return (
        <div className={globalStyle.containerTablet}>
            {!mediator.isConnected && (
                <PreConnectionMediatorScreen
                    deviceStatuses={[
                        { label: 'Bleu', status: blue.isConnected, color: 'blue' },
                        {
                            label: 'Orange',
                            status: orange.isConnected,
                            color: 'orange',
                        },
                        { label: 'Rouge', status: red.isConnected, color: 'red' },
                        {
                            label: 'Verte',
                            status: green.isConnected,
                            color: 'green',
                        },
                    ]}
                    handleConnect={() => {
                        emitSocketEvent('send_start_game', {
                            color: 'mediator',
                        });
                    }}
                />
            )}
            {mediator.isConnected && <MediatorScreen />}
        </div>
    );
}
