import data from '@/data/gameOneData.json';
import globalData from '@/data/global.json';

import ErrorAndSuccessScreen from '@/features/components/modal/errorAndSuccess/ErrorAndSuccess';
import { useGame1Store } from '@/store/games/game1Store';
import { useGameStore } from '@/store/gameStore';
import { useMemo, useState } from 'react';
import type { GameResult } from '@/types/games/types';

export default function InfoManager({
    gameResult,
}: {
    gameResult: Exclude<GameResult, ''>;
}) {
    const target = useGameStore.use.target();
    const tirePressureInfoStatus = useGame1Store.use.tirePressureInfoStatus();
    const [text, setText] = useState('');

    const [title, isSuccess] = useMemo(() => {
        const title =
            gameResult === 'error'
                ? globalData.global.warning
                : globalData.global.perfect;
        const isSuccess = gameResult === 'success';

        switch (target) {
            case 'SEATBELT':
                setText(data.seatBeltFasten);
                break;
            case 'TIRE':
                setText(data.tirePressureInfoStatus[tirePressureInfoStatus]);
                break;
            case 'PHONE':
                setText(data.phone[gameResult]);
                break;
            case 'ENGINE':
                setText(data.engineStart[gameResult]);
                break;
            default:
                break;
        }

        return [title, isSuccess];
    }, [gameResult, target, tirePressureInfoStatus]);

    return <ErrorAndSuccessScreen title={title} text={text} success={isSuccess} />;
}
