import { CarImage, TruckImage } from '@/assets/images/global';
import data from '@/data/global.json';
import Conversation from '@/features/components/modal/carouselConversation/Conversation';
import styles from '@/features/components/modal/carouselConversation/carouselConversation.module.scss';
import Button from '@/features/devices/tablets/components/buttons/Button';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { useGameStore } from '@/store/gameStore';

export default function CarouselConversation() {
    const { currentTitle, currentText } = useCurrentOverlay();
    const vehicle = useGameStore.use.vehicle();

    if (!vehicle) return null;

    const vehicleToDisplay = vehicle === 'car' ? CarImage : TruckImage;
    let enrichedText = '';

    enrichedText = currentText.replace(/\[VEHICLE_NAME\]/g, data.global[vehicle]);

    return (
        <div className={styles.container}>
            <div>
                <Conversation title={currentTitle} text={enrichedText} />
                <img
                    src={vehicleToDisplay}
                    alt='voiture ou camion'
                    className={styles[vehicle]}
                />
            </div>
            <div>
                <div>
                    <Button
                        color='white'
                        text={data.global.waitingTablet}
                        onClick={() => null}
                        disabled={true}
                    />
                </div>
            </div>
        </div>
    );
}
