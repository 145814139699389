import Button from '@/features/devices/tablets/components/buttons/Button';
import SelectObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectObjectButton';
import styles from '@/features/devices/tablets/components/buttons/gameTwo/selectTruckObjectButton.module.scss';
import { handleTruckObjectSelectedGametwo } from '@/services/games/game2/gameTwoHandleFunctions';
import { useGame2Store } from '@/store/games/game2Store';
import type { DeviceColor } from '@/types/global/types';

export default function SelectTruckObjectButton({ color }: { color: DeviceColor }) {
    const currentItem = useGame2Store.use.selectedTruckObjects()[color];
    const isCurrentItemStrapped = currentItem?.isStrapped;

    const handleStrapButtonClick = () => {
        if (!currentItem) return;

        handleTruckObjectSelectedGametwo(
            currentItem,
            color,
            !isCurrentItemStrapped
        );
    };

    return (
        <div className={styles.container}>
            <div>
                <h1>Zone 1</h1>
            </div>
            <div>
                <p>{'Selectionner un objet'}</p>
                <SelectObjectButton
                    type='truck'
                    disabled={isCurrentItemStrapped!}
                    color={color}
                />
                <Button
                    onClick={handleStrapButtonClick}
                    text="Sangler l'objet"
                    color='black'
                />
            </div>
        </div>
    );
}
