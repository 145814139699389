import { type Socket, io } from 'socket.io-client';
import type { ClientEvents, ServerEvents } from '@/types/socket/types';

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

class SocketService {
    private static instance: SocketService;
    private socket: Socket<ServerEvents, ClientEvents> | null = null;

    public static getInstance(): SocketService {
        if (!SocketService.instance) {
            SocketService.instance = new SocketService();
        }

        return SocketService.instance;
    }

    private connect(): void {
        this.socket = io(BACKEND_URL, { transports: ['websocket'] });
    }

    public getSocket(): Socket<ServerEvents, ClientEvents> {
        if (!this.socket) {
            this.connect();
        }
        return this.socket as Socket<ServerEvents, ClientEvents>;
    }
}

export default SocketService;
