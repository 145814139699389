import { create } from 'zustand';
import { TvImage } from '@/assets/images/global';
import createSelectors from '@/store/selectors';
import type { DeviceStore } from '@/types/games/types';
import type { DeviceColorWithTvAndMediator } from '@/types/global/types';
import { isDevelopperMode } from '@/services/global/globalUtils';

const useDeviceStoreBase = create(
    (): DeviceStore => ({
        deviceStatus: {
            red: {
                isConnected: false,
                isActivated: false,
                label: 'Tablette rouge',
            },
            blue: {
                isConnected: false,
                isActivated: false,
                label: 'Tablette bleue',
            },
            green: {
                isConnected: false,
                isActivated: false,
                label: 'Tablette verte',
            },
            orange: {
                isConnected: false,
                isActivated: isDevelopperMode ? true : false,
                label: 'Tablette orange',
            },
            tv: {
                isConnected: true,
                isActivated: true,
                label: 'TV',
                image: TvImage,
            },
            mediator: {
                isConnected: false,
                isActivated: true,
                label: 'Tablette Médiateur',
            },
        },
    })
);

export const useDeviceStore = createSelectors(useDeviceStoreBase);

export const setDeviceConnectedStatus = (
    deviceColor: DeviceColorWithTvAndMediator,
    status: boolean
): void => {
    useDeviceStoreBase.setState((state) => ({
        deviceStatus: {
            ...state.deviceStatus,
            [deviceColor]: {
                ...state.deviceStatus[deviceColor],
                isConnected: status,
            },
        },
    }));
};

export const setDeviceActivatedStatus = (
    deviceColor: DeviceColorWithTvAndMediator,
    status: boolean
): void => {
    useDeviceStoreBase.setState((state) => ({
        deviceStatus: {
            ...state.deviceStatus,
            [deviceColor]: {
                ...state.deviceStatus[deviceColor],
                isActivated: status,
            },
        },
    }));
};
