import { CarImage, TruckImage } from '@/assets/images/global';
import globalData from '@/data/global.json';
import Button from '@/features/devices/tablets/components/buttons/Button';
import { useVehicleChange } from '@/hooks/useVehicleChange';
import { handleVehicleSelection } from '@/services/global/globalHandleFunctions';
import type { Vehicle } from '@/types/games/types';
import styles from './selectVehicle.module.scss';

export default function SelectVehicle({ type }: { type: Vehicle }) {
    const shouldBeDisabled = useVehicleChange();

    return (
        <div className={styles.container}>
            <img
                src={type === 'car' ? CarImage : TruckImage}
                alt={type === 'car' ? 'Voiture' : 'Camion'}
                width={1024}
            />
            <div className={styles.action}>
                <Button
                    text={globalData.tablet.choseVehicle}
                    color='black'
                    onClick={() => handleVehicleSelection(type)}
                    disabled={shouldBeDisabled}
                />
            </div>
        </div>
    );
}
