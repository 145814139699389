import styles from '@/assets/styles/app.module.scss';
import InfoManager from '@/features/components/infoManager/InfoManager';
import { checkIsGamePrevention } from '@/services/global/globalUtils';
import { useGameStore } from '@/store/gameStore';
import type { PropsWithChildren } from 'react';

export default function TvLayout({ children }: PropsWithChildren) {
    const { gameStatus, gameResult } = useGameStore();
    const isGamePrevention = checkIsGamePrevention(gameStatus);

    return (
        <div
            className={
                isGamePrevention ? styles.tvContainerPrevention : styles.tvContainer
            }
        >
            {gameResult !== '' && <InfoManager gameResult={gameResult} />}
            {children}
        </div>
    );
}
