import styles from '@/features/components/modal/carouselConversation/conversation.module.scss';
import TextAnimation from '@/features/components/text/TextAnimation';

interface ConversationProps {
    text?: string;
    title?: string | null;
    audio?: string;
}

export default function Conversation({ text, title = null }: ConversationProps) {
    return (
        <div className={styles.container}>
            <div>
                {title && (
                    <div className={styles.title}>
                        <h2>{title}</h2>
                    </div>
                )}
                {text && <TextAnimation text={text} />}
            </div>
        </div>
    );
}
