import {
    assertTruckObjectIsNotNull,
    isDeliveryOrderRespected,
    isWeightWellDistributed,
} from '@/services/games/game2/gameTwoUtils';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useGame2Store } from '@/store/games/game2Store';
import type {
    CarLocation,
    CarObject,
    TruckObject,
} from '@/types/games/gameTwo/types';
import type { DeviceColor } from '@/types/global/types';

export function handleTruckObjectSelectedGametwo(
    object: TruckObject,
    deviceColor: DeviceColor,
    isStrapped: boolean
): void {
    emitSocketEvent('send_truck_objects_selected_game_two', {
        object,
        deviceColor,
        isStrapped,
    });
}

export function handleCarObjectSelectedGameTwo(
    carLocation: CarLocation,
    object: CarObject,
    color: DeviceColor
): void {
    emitSocketEvent('send_car_objects_selected_game_two', {
        carLocation,
        object,
        color,
    });
}

export function handleValidateGameTwo(): void {
    const selectedTruckObjects = useGame2Store.getState().selectedTruckObjects;

    assertTruckObjectIsNotNull(selectedTruckObjects);

    if (
        isWeightWellDistributed(selectedTruckObjects) &&
        isDeliveryOrderRespected(selectedTruckObjects)
    ) {
        emitSocketEvent('send_validate_game_two', {
            type: 'success',
        });
        return;
    }
    emitSocketEvent('send_validate_game_two', {
        type: 'error',
    });
}
