import { Error, Success } from '@/assets/images/icons';
import data from '@/data/global.json';
import Button from '@/features/devices/tablets/components/buttons/Button';
import { handleGameResult } from '@/services/global/globalHandleFunctions';
import { AnimatePresence, motion } from 'framer-motion';
import type { DeviceColor } from '@/types/global/types';
import styles from './errorAndSuccess.module.scss';

interface ErrorAndSuccessScreenProps {
    title: string;
    text: string;
    success: boolean;
    color?: DeviceColor;
    audio?: string;
}

const variants = {
    entering: { x: '0%', y: '150%', opacity: 0 },
    entered: { x: '0%', y: '100%', opacity: 1 },
    exiting: { x: '0%', y: '150%', opacity: 0 },
};

export default function ErrorAndSuccessScreen({
    title,
    text,
    success = false,
}: ErrorAndSuccessScreenProps) {
    return (
        <div className={styles.wrapper}>
            <AnimatePresence mode='popLayout'>
                <motion.div
                    key={text}
                    initial='entering'
                    animate='entered'
                    exit='exiting'
                    variants={variants}
                    transition={{ type: 'tween' }}
                    className={styles.container}
                >
                    <div>
                        <img
                            src={success ? Success : Error}
                            alt='green check or red cross'
                        />
                        <h1>{title}</h1>
                        <h3>{text}</h3>

                        <div>
                            <Button
                                color='white'
                                text={data.global.next}
                                onClick={() => handleGameResult('')}
                            />
                        </div>
                    </div>
                </motion.div>
            </AnimatePresence>
        </div>
    );
}
