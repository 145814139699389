import styles from '@/features/devices/tablets/mediator/statusIndicator.module.scss';

export default function StatusIndicator({ status }: { status: boolean }) {
    const statusClass = status ? styles.connectedStatus : styles.disconnectedStatus;

    return (
        <div className={styles.statusContainer}>
            <div className={`${styles.status} ${statusClass}`} />
            {status ? 'En ligne ' : 'Déconnecté'}
        </div>
    );
}
