import createSelectors from '@/store/selectors';
import {
    OverlayType,
    type OverlayDescription,
    type OverlayStore,
} from '@/types/games/types';
import { create } from 'zustand';

const useOverlayStoreBase = create<OverlayStore>(() => ({
    currentOverlayIndex: 0,
    overlayDescriptions: [] as OverlayDescription[],
    overlayType: OverlayType.NEUTRAL,
}));

export const useOverlayStore = createSelectors(useOverlayStoreBase);

export const setCurrentOverlayIndex = (index: number): void => {
    useOverlayStoreBase.setState({
        currentOverlayIndex: index,
    });
};

export const setCurrentOverlayDescriptions = (
    overlayDescriptions: OverlayDescription[],
    type: OverlayType
): void => {
    useOverlayStoreBase.setState({
        overlayDescriptions,
        overlayType: type,
        currentOverlayIndex: 0,
    });
};

export const setCurrentOverlayType = (type: OverlayType): void => {
    useOverlayStoreBase.setState({
        overlayType: type,
    });
};
