import data from '@/data/global.json';
import CarStatus from '@/features/components/cockpit/components/CarStatus';
import Speedometer from '@/features/components/cockpit/components/Speedometer';
import TirePressure from '@/features/components/gameOne/tirePressure/TirePressure';
import Clock from '@/features/devices/tablets/orange/components/phone/Clock';
import { useDynamicTabletName } from '@/hooks/useDynamicTabletName';
import { handleCockpitAction } from '@/services/global/globalHandleFunctions';
import { useGame1Store } from '@/store/games/game1Store';
import { useGameStore } from '@/store/gameStore';
import { useEffect } from 'react';
import type { DeviceColorWithTV } from '@/types/global/types';
import styles from './cockpit.module.scss';

interface CockpitProps {
    device: DeviceColorWithTV;
}

export default function Cockpit({ device }: CockpitProps) {
    const gameStatus = useGameStore.use.gameStatus();
    const { tirePressureInfoStatus } = useGame1Store();
    const { cockpitStatus, gameResult } = useGameStore();

    useDynamicTabletName(device, 'Cockpit');

    useEffect(() => {
        if (tirePressureInfoStatus === 'good' && gameResult === 'success') {
            handleCockpitAction('COCKPIT');
        }
    }, [gameResult, tirePressureInfoStatus]);

    const render = () => {
        switch (cockpitStatus) {
            case 'TIRE':
                return <TirePressure />;
            case 'ETA':
            case 'VIEW':
                return null;
            case 'MAP':
                return;
            default:
                return (
                    <div className={styles.container}>
                        <div className={styles.action_bar}>
                            <div>
                                <button
                                    className={styles.action}
                                    type='button'
                                    onClick={() => handleCockpitAction('TIRE')}
                                >
                                    pression des pneux
                                </button>

                                <button
                                    className={styles.action}
                                    type='button'
                                    onClick={() => handleCockpitAction('ETA')}
                                >
                                    Etat du vehicule
                                </button>
                                <button
                                    className={styles.action}
                                    type='button'
                                    onClick={() => handleCockpitAction('VIEW')}
                                >
                                    Vue du vehicule
                                </button>
                                <button
                                    className={styles.action}
                                    type='button'
                                    onClick={() => handleCockpitAction('MAP')}
                                    disabled={gameStatus === 'GAME1ACTION'}
                                >
                                    Plan
                                </button>
                            </div>
                        </div>
                        <div className={styles.info_grid}>
                            <div>
                                <div className={styles.info}>
                                    {data.cockpit.welcome}
                                </div>
                            </div>
                            <div>
                                <Clock />
                            </div>
                            <Speedometer />
                            <CarStatus />
                        </div>
                    </div>
                );
        }
    };

    return <div>{render()}</div>;
}
