import overlayData from '@/data/overlayDeviceContent.json';
import { useGameStore } from '@/store/gameStore';
import styles from './vehicleSelection.module.scss';

export default function VehicleSelection() {
    const vehicleSelected = useGameStore.use.vehicle();
    const content = overlayData['VEHICLE_SELECTION'].tv[0].content;

    return (
        <div className={styles.container}>
            {content.map((vehicle) => (
                <div
                    key={vehicle.id}
                    className={`${styles.vehicle_card} ${vehicleSelected === vehicle.id ? styles.selected : ''}`}
                >
                    <div className={styles.vehicle_image}>
                        <img src={vehicle.image} alt={vehicle.id} width={512} />
                    </div>
                    <div className={styles.text_content}>
                        <h1 className={styles.vehicle_title}>{vehicle.title}</h1>
                    </div>
                </div>
            ))}
        </div>
    );
}
