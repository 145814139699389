import createSelectors from '@/store/selectors';
import { deepCopy, INITIAL_GAME5_STORE } from '@/sessionInfo/sessionInfo';
import type { Game5Store, Reaction, Situation } from '@/types/games/gameFive/types';
import { create } from 'zustand';

const useGame5StoreBase = create<Game5Store>(() => deepCopy(INITIAL_GAME5_STORE));

export const useGame5Store = createSelectors(useGame5StoreBase);

export const setCurrentSituation = (currentSituation: Situation) =>
    useGame5StoreBase.setState({ currentSituation });

export const setPlayersAnswer = (playersAnswer: Reaction) =>
    useGame5StoreBase.setState({ playersAnswer });
