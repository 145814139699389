import { EndScreen, MainScreen } from '@/assets/images/global';
import { Ambiance } from '@/assets/sounds/soundsEffects';
import AstraLoading from '@/features/components/astraLoading/AstraLoading';
import GameFive from '@/features/components/gameFive/GameFive';
import GameFour from '@/features/components/gameFour/GameFour';
import GameOne from '@/features/components/gameOne/GameOne';
import GameTwoCar from '@/features/components/gameTwo/GameTwoCar';
import GameTwoTruck from '@/features/components/gameTwo/GameTwoTruck';
import CarouselConversation from '@/features/components/modal/carouselConversation/CarouselConversation';
import TvLayout from '@/features/devices/tv/components/TvLayout';
import VehicleSelection from '@/features/devices/tv/components/vehicleSelection/VehicleSelection';
import { useDynamicTabletName } from '@/hooks/useDynamicTabletName';
import { useGameStore } from '@/store/gameStore';
import { useEffect, useState } from 'react';
import { GAMESTATUS } from '@/types/global/types';

export default function Tv() {
    const [isFirstRender, setIsFirstRender] = useState(true);
    const gameStatus = useGameStore.use.gameStatus();
    const vehicle = useGameStore.use.vehicle();

    const isFinished = gameStatus === GAMESTATUS.FINISHED;

    useDynamicTabletName('tv', 'TV');

    useEffect(() => {
        if (gameStatus === GAMESTATUS.WELCOME_SCREEN) return;
        if (!isFirstRender) return;

        const ambianceSound = new Audio(Ambiance);
        ambianceSound.loop = true;
        ambianceSound.volume = 0.2;
        ambianceSound.play();
        setIsFirstRender(false);
    }, [gameStatus, isFirstRender]);

    const handleRender = () => {
        switch (gameStatus) {
            case GAMESTATUS.WELCOME_SCREEN:
            case GAMESTATUS.FINISHED:
                return (
                    <img
                        src={isFinished ? EndScreen : MainScreen}
                        alt="écran d'accueil ou de fin"
                    />
                );
            case GAMESTATUS.VEHICLE_SELECTION:
                return <VehicleSelection />;
            case GAMESTATUS.GAME1INTRODUCTION_LOADING:
                return <AstraLoading device='tv' />;
            case GAMESTATUS.GAME1INTRODUCTION:
            case GAMESTATUS.GAME2INTRODUCTION:
            case GAMESTATUS.GAME5INTRODUCTION:
                // case GAMESTATUS.GAME3INTRODUCTION:
                // case GAMESTATUS.GAME4INTRODUCTION:
                return <CarouselConversation />;
            case GAMESTATUS.GAME1ACTION:
                return <GameOne />;
            case GAMESTATUS.GAME2ACTION:
                return vehicle === 'car' ? <GameTwoCar /> : <GameTwoTruck />;
            // case GAMESTATUS.GAME3ACTION:
            case GAMESTATUS.GAME4ACTION:
                return <GameFour />;
            case GAMESTATUS.GAME5ACTION:
                return <GameFive />;
            // case GAMESTATUS.GAME1PREVENTION:
            // case GAMESTATUS.GAME3PREVENTION:
            // case GAMESTATUS.GAME4PREVENTION:
            // return <Prevention />;
            // case GAMESTATUS.GAME1UNLOCK:
            // case GAMESTATUS.GAME3UNLOCK:
            // case GAMESTATUS.GAME4UNLOCK:
        }
    };

    return <TvLayout>{handleRender()}</TvLayout>;
}
