import styles from '@/features/devices/tablets/components/buttons/gameTwo/selectCarObjectButton.module.scss';
import SelectObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectObjectButton';
import { mapColorWithCarPosition } from '@/services/games/game2/gameTwoUtils';
import type { DeviceColor } from '@/types/global/types';

export default function SelectCarObjectButton({ color }: { color: DeviceColor }) {
    const carLocationByColor = mapColorWithCarPosition(color);

    return (
        <div className={styles.container}>
            {carLocationByColor.map((location) => (
                <SelectObjectButton
                    type='car'
                    key={location}
                    color={color}
                    carLocation={location}
                />
            ))}
        </div>
    );
}
