import { Astra, FingerPrint } from '@/assets/images/global';
import data from '@/data/global.json';
import Clock from '@/features/devices/tablets/orange/components/phone/Clock';
import styles from '@/features/devices/tablets/orange/components/phone/phone.module.scss';

export default function LockScreen() {
    return (
        <div className={styles.lock_screen}>
            <div className={styles.header}>
                <img src={Astra} alt='astra' width={512} />
                <h3>{data.global.pushToUnlock}</h3>
            </div>
            <Clock />
            <div id='unlock'>
                <img src={FingerPrint} alt='empreinte digitale' width={128} />
            </div>
        </div>
    );
}
