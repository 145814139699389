import { GouvHead, MeteoHead } from '@/assets/images/characters';
import { MaxHead } from '@/assets/images/global';
import gameOneData from '@/data/gameOneData.json';
import styles from '@/features/devices/tablets/orange/components/phone/phone.module.scss';
import { useGame1Store } from '@/store/games/game1Store';

const ICONS_MAP = {
    gouvHead: GouvHead,
    meteoHead: MeteoHead,
    maxHead: MaxHead,
};

type IconsMapKey = keyof typeof ICONS_MAP;

export default function Messages() {
    const messages = gameOneData.messages;

    const shouldDisplayWarning = useGame1Store.use.isDoNotDisturbOn();
    return (
        <div className={styles.list}>
            <h3>Messages</h3>
            {!shouldDisplayWarning && (
                <p className={styles.prevention}>
                    Attention ! L&apos;utilisation du téléphone portable au volant
                    est interdite
                </p>
            )}
            {messages.map((msg) => (
                <div className={styles.row} key={msg.id}>
                    <img
                        src={ICONS_MAP[msg.icon as IconsMapKey]}
                        alt='avatar'
                        width={96}
                    />
                    <p>{msg.from}</p>
                    <p>{msg.preview}</p>
                </div>
            ))}
        </div>
    );
}
