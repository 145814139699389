import SocketService from '@/services/SocketServices';
import { useGameStore } from '@/store/gameStore';
import { useRoomStore } from '@/store/roomStore';
import { type Socket } from 'socket.io-client';
import { GAMESTATUS, type GameStatus } from '@/types/global/types';
import type { ClientEvents } from '@/types/socket/types';

export const isDevelopperMode = import.meta.env.VITE_DEVELOPPER_MODE === 'true';

export const SHAKE_ANIMATION = {
    x: [-10, 10, -10, 0],
    transition: {
        times: [0, 0.2, 0.8, 1],
        duration: 0.3,
        ease: 'easeInOut',
    },
};

export const getSocket = (): Socket | null => {
    const socketService = SocketService.getInstance();
    return socketService.getSocket();
};

/**
 * Emits a socket event with the given name and data, with the room name.
 * @param {keyof ClientEvents} eventName - The name of the event.
 * @param {Omit<Parameters<ClientEvents[EventKey]>[number], 'roomName'>} data - The data to send depending on the event.
 */
export function emitSocketEvent<EventKey extends keyof ClientEvents>(
    eventName: EventKey,
    data: Omit<Parameters<ClientEvents[EventKey]>[number], 'roomName'>
): void {
    const socket = SocketService.getInstance().getSocket();
    const roomName = useRoomStore.getState().roomName;

    if (!socket) return;
    if (roomName === '') {
        // eslint-disable-next-line no-console
        console.info(`Room name was empty calling ${eventName}.`);
        return;
    }
    const params = (data ? [{ ...data, roomName }] : [{ roomName }]) as Parameters<
        ClientEvents[EventKey]
    >;
    socket.emit(eventName, ...params);
}

/**
 * Object to handle the tablet status depending on the game status.
 */
export const tabletStatusGameConfig = {
    [GAMESTATUS.WELCOME_SCREEN]: {
        blue: false,
        green: false,
        orange: true,
        red: false,
    },
    [GAMESTATUS.VEHICLE_SELECTION]: {
        blue: true,
        green: false,
        orange: true,
        red: false,
    },
    [GAMESTATUS.GAME1INTRODUCTION_LOADING]: {
        blue: true,
        green: true,
        orange: true,
        red: true,
    },
    [GAMESTATUS.GAME1INTRODUCTION]: {
        blue: false,
        green: true,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME1ACTION]: {
        blue: true,
        green: true,
        orange: true,
        red: true,
    },
    [GAMESTATUS.GAME1PREVENTION]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME1UNLOCK]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME2INTRODUCTION]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME2ACTION]: {
        blue: true,
        green: true,
        orange: true,
        red: true,
    },
    [GAMESTATUS.GAME3INTRODUCTION]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME3ACTION]: {
        blue: false,
        green: true,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME3PREVENTION]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME3UNLOCK]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME4INTRODUCTION]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME4ACTION]: {
        blue: true,
        green: false,
        orange: true,
        red: false,
    },
    [GAMESTATUS.GAME4PREVENTION]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME4UNLOCK]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME5INTRODUCTION]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME5ACTION]: {
        blue: true,
        green: true,
        orange: true,
        red: true,
    },
    [GAMESTATUS.GAME5PREVENTION]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME5UNLOCK]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.FINISHED]: {
        blue: false,
        green: false,
        orange: false,
        red: false,
    },
};

/**
 * Object to handle the tablet status depending on the event.
 */
export const tabletStatusEventConfig = {
    allRisksOrLeversUnlocked: {
        blue: false,
        green: true,
        orange: true,
        red: false,
    },
    risksFound: {
        blue: false,
        green: false,
        orange: false,
        red: true,
    },
    headEmployeGame2Selected: {
        blue: true,
        red: true,
        green: true,
        orange: false,
    },
};

export const getNextGameStatus = (): GameStatus => {
    const gameStatus = useGameStore.getState().gameStatus;
    const indexCurrentGameStatus = Object.keys(GAMESTATUS).findIndex(
        (status) => status === gameStatus
    );
    return Object.keys(GAMESTATUS)[indexCurrentGameStatus + 1] as GameStatus;
};

export const isObjectEmpty = (obj?: unknown): boolean => {
    if (!obj) return true;
    return Object.keys(obj).length === 0;
};

export const areArraysEqual = (arr1: unknown[], arr2: unknown[]): boolean => {
    return (
        arr1.length === arr2.length &&
        arr1.every((value, index) => value === arr2[index])
    );
};

export const isNumberInArray = (array: number[], number: number): boolean => {
    return array.includes(number);
};

/**
 * Checks if the given key is a key of the given object.
 */
export function isKey<T extends object>(x: T, k: PropertyKey): k is keyof T {
    return k in x;
}

/**
 * Replace the {@link Object.keys} function to be typed.
 * @param obj - The object to get the keys from.
 * @returns The keys of the object.
 */
export const objectKeys = <T extends object>(obj: T): Array<keyof T> => {
    return Object.keys(obj) as Array<keyof T>;
};

export function isCarSelected(): boolean {
    return useGameStore.getState().vehicle === 'car';
}

export function mapTabletColorToName(color: string): string {
    switch (color) {
        case 'blue':
            return 'bleu';
        case 'green':
            return 'vert';
        case 'orange':
            return 'orange';
        case 'red':
            return 'rouge';
        default:
            return '';
    }
}
/**
 *
 * @param status actual game status
 * @returns boolean. Wether or not the game is in a prevention status
 */
export const checkIsGamePrevention = (status: GameStatus): boolean => {
    //TODO: Add game 2 prevention
    return (
        status ===
        (GAMESTATUS.GAME1PREVENTION ||
            GAMESTATUS.GAME3PREVENTION ||
            GAMESTATUS.GAME4PREVENTION)
    );
};
