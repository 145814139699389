import Button from '@/features/devices/tablets/components/buttons/Button';
import styles from '@/features/devices/tablets/orange/components/phone/phone.module.scss';
import {
    handleGameResult,
    handleReceiveCall,
} from '@/services/global/globalHandleFunctions';

import gameOneData from '@/data/gameOneData.json';
import data from '@/data/global.json';

export default function CallEntry() {
    const caller = gameOneData.callerPool[Math.floor(Math.random() * 3)];

    const handleManageCall = (action: 'pickup' | 'hangup'): void => {
        if (action === 'pickup') {
            handleGameResult('error');
        }
        handleReceiveCall(false);
    };
    return (
        <div className={styles.call_entry}>
            <h1>{caller.caller}</h1>
            <h3>{data.global.incomingCall}</h3>
            <div className={styles.actions}>
                <Button
                    text={data.global.ignore}
                    onClick={() => handleManageCall('hangup')}
                    color='grey'
                />
                <Button
                    text={data.global.answer}
                    onClick={() => handleManageCall('pickup')}
                    color='green'
                />
            </div>
        </div>
    );
}
