import styles from '@/features/components/gameTwo/gameTwo.module.scss';
import { handleValidateGameTwo } from '@/services/games/game2/gameTwoHandleFunctions';
import { useGame2Store } from '@/store/games/game2Store';
import { useEffect } from 'react';

export default function GameTwoTruck() {
    const selectedItems = useGame2Store.use.selectedTruckObjects();

    useEffect(() => {
        if (Object.values(selectedItems).every((item) => item?.isStrapped)) {
            handleValidateGameTwo();
        }
    }, [selectedItems]);

    const positions = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'];

    return (
        <div className={styles.truckContainer}>
            <h1>Chargement du vehicule en cours...</h1>
            <div className={styles.truck}>
                <div className={styles.truckLoad}>
                    {Object.entries(selectedItems).map(([color, object], index) => (
                        <div
                            key={`${color + index}`}
                            className={styles[positions[index]]}
                        >
                            <img src={object?.image} alt='vehicule' />
                            <p>
                                {object?.isStrapped ? 'strapped' : 'not strapped'}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <div></div>
        </div>
    );
}
