import styles from '@/features/devices/tablets/components/gameFive/keypad.module.scss';
import { handleSelectSituation } from '@/services/games/game5/gameFiveHandleFunctions';
import { getSymbolSituations } from '@/services/games/game5/gameFiveUtils';
import { useGame5Store } from '@/store/games/game5Store';
import { motion } from 'framer-motion';

export default function Keypad() {
    const symbols = getSymbolSituations();
    const currentSituation = useGame5Store.use.currentSituation();

    return (
        <div className={styles.container}>
            {symbols.map((symbol) => (
                <KeypadCard
                    key={symbol}
                    symbol={symbol}
                    onClick={handleSelectSituation}
                    isSelected={currentSituation?.symbol === symbol}
                />
            ))}
        </div>
    );
}

function KeypadCard({
    symbol,
    onClick,
    isSelected,
}: {
    symbol: string;
    onClick: (symbol: string) => void;
    isSelected: boolean;
}) {
    return (
        <motion.button
            className={
                isSelected
                    ? styles.keypadCardContainerSelected
                    : styles.keypadCardContainer
            }
            whileTap={{
                scale: 0.9,
            }}
            onClick={() => onClick(symbol)}
        >
            <p>{symbol}</p>
        </motion.button>
    );
}
