import globalData from '@/data/global.json';
import AstraLoading from '@/features/components/astraLoading/AstraLoading';
import TirePressure from '@/features/components/gameOne/tirePressure/TirePressure';
import SelectVehicle from '@/features/components/vehicleSelection/SelectVehicle';
import ActionButton from '@/features/devices/tablets/components/buttons/ActionButton';
import EngineStartButton from '@/features/devices/tablets/components/buttons/EngineStartButton';
import SelectCarObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectCarObjectButton';
import SelectTruckObjectButton from '@/features/devices/tablets/components/buttons/gameTwo/SelectTruckObjectButton';
import FullScreenButton from '@/features/devices/tablets/components/gameFive/FullScreenButton';
import SelectSubstance from '@/features/devices/tablets/components/gameFour/SelectSubstance';
import TabletLayout from '@/features/devices/tablets/components/screens/TabletLayout';
import { useDynamicTabletName } from '@/hooks/useDynamicTabletName';
import { isCarSelected } from '@/services/global/globalUtils';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { GAMESTATUS } from '@/types/global/types';

export default function BlueActionSwitch() {
    const { gameStatus, cockpitStatus } = useGameStore();
    const { blue } = useDeviceStore.use.deviceStatus();

    useDynamicTabletName('blue', blue.label);

    const handleRender = () => {
        switch (gameStatus) {
            case GAMESTATUS.WELCOME_SCREEN:
                return (
                    <>
                        <ActionButton
                            title={globalData.global.start}
                            action='startGame'
                            color='black'
                        />
                    </>
                );
            case GAMESTATUS.VEHICLE_SELECTION:
                return <SelectVehicle type='truck' />;
            case GAMESTATUS.GAME1INTRODUCTION_LOADING:
                return <AstraLoading device='blue' />;
            case GAMESTATUS.GAME2INTRODUCTION:
            case GAMESTATUS.GAME3INTRODUCTION:
            case GAMESTATUS.GAME4INTRODUCTION:
            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.GAME3PREVENTION:
            case GAMESTATUS.GAME4PREVENTION:
            case GAMESTATUS.GAME1UNLOCK:
            case GAMESTATUS.GAME3UNLOCK:
            case GAMESTATUS.GAME4UNLOCK:
                return (
                    <ActionButton
                        action='goToNextOverlay'
                        title={globalData.global.next}
                        color='black'
                    />
                );
            case GAMESTATUS.GAME1ACTION:
                return cockpitStatus === 'TIRE' ? (
                    <TirePressure tire='RightRear' />
                ) : (
                    <EngineStartButton />
                );
            case GAMESTATUS.GAME2ACTION:
                return isCarSelected() ? (
                    <SelectCarObjectButton color='blue' />
                ) : (
                    <SelectTruckObjectButton color='blue' />
                );
            case GAMESTATUS.GAME4ACTION:
                return <SelectSubstance color='blue' />;
            case GAMESTATUS.GAME5ACTION:
                return <FullScreenButton color='blue' text='Courtoisie' />;
            case GAMESTATUS.FINISHED:
                break;
            default:
                break;
        }
    };

    return <TabletLayout device={blue}>{handleRender()}</TabletLayout>;
}
