import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import { useOverlayStore } from '@/store/overlayStore';

import {
    handleResetSeatBelt,
    handleResetTirePressure,
    handleSeatBeltFastening,
    handleSetDoNotDisturb,
    handleTirePressureValidation,
} from '@/services/global/globalHandleFunctions';
import type { MediatorButtonAction } from '@/services/mediator/types.mediator';
import { useGameStore } from '@/store/gameStore';
import type { Maybe } from '@/types/global/types';

import dataGameOne from '@/data/gameOneData.json';
import { setTirePressure } from '@/store/games/game1Store';

interface GameOneMediatorProps {
    actions: Maybe<MediatorButtonAction>;
}

export default function GameOneMediator({ actions }: GameOneMediatorProps) {
    const { currentOverlayIndex, overlayDescriptions } = useOverlayStore.getState();
    const vehicle = useGameStore.use.vehicle();

    const handleActionClick = (action: string): void => {
        switch (action) {
            case 'fastenSeatBelt':
                handleSeatBeltFastening(true);
                break;
            case 'resetSeatBelt':
                handleResetSeatBelt();
                break;
            case 'setTirePressure':
                if (!vehicle) return;
                setTirePressure(dataGameOne[`${vehicle}TirePressure`]);
                handleTirePressureValidation();
                break;
            case 'resetTirePressure':
                handleResetTirePressure();
                break;
            case 'setDoNotDisturb':
                handleSetDoNotDisturb(true);
                break;
            case 'resetDoNotDisturb':
                handleSetDoNotDisturb(false);
                break;
            default:
                break;
        }
    };

    return (
        <div>
            {/* Next message */}
            <MediatorAction
                title='Passer au message suivant'
                displayingCondition={overlayDescriptions.length > 0}
                handleFunction={() => {
                    // handleGoToNextOrPreviousJobOverlay('next')
                }}
                type='secondary'
            />

            {/* Previous message */}
            <MediatorAction
                title='Revenir au message précédent'
                displayingCondition={
                    currentOverlayIndex > 0 && overlayDescriptions.length > 0
                }
                handleFunction={() => {
                    // handleGoToNextOrPreviousJobOverlay('previous')
                }}
                type='secondary'
            />
            {actions &&
                Object.keys(actions).map((label) => (
                    <MediatorAction
                        key={actions[label].btnTitle}
                        title={actions[label].btnTitle}
                        handleFunction={() =>
                            handleActionClick(actions[label].nameFunctions[0])
                        }
                        displayingCondition={actions != null}
                        type='secondary'
                    />
                ))}
        </div>
    );
}
