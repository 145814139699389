import TireImg from '@/assets/images/global/tire.svg';
import gameOneData from '@/data/gameOneData.json';
import data from '@/data/global.json';
import Button from '@/features/devices/tablets/components/buttons/Button';
import {
    handleCockpitAction,
    handleTirePressureUpdate,
    handleTirePressureValidation,
} from '@/services/global/globalHandleFunctions';
import { useGame1Store } from '@/store/games/game1Store';
import type { Tire } from '@/types/games/gameOne/types';
import type { DeviceColor } from '@/types/global/types';
import styles from './tirePressure.module.scss';

interface TirePressureProps {
    tire: Tire;
    device?: DeviceColor;
}

const tireNameMap = {
    LeftFront: 'Avant Gauche',
    LeftRear: 'Arrière Gauche',
    RightFront: 'Avant Droit',
    RightRear: 'Arrière Droit',
} as const;

export default function TirePressure({ device, tire }: TirePressureProps) {
    const tirePressure = useGame1Store.use.tiresPressure()[tire];
    const tireName = tireNameMap[tire];

    const adjustPressure = (action: 'up' | 'down'): void => {
        const amount = action === 'up' ? +0.1 : -0.1;
        handleTirePressureUpdate(tire, tirePressure + amount);
    };

    return (
        <div className={styles.container}>
            <header>
                {device && device === 'red' ? (
                    <button
                        type='button'
                        onClick={() => handleCockpitAction('COCKPIT')}
                    >
                        {'<'}
                    </button>
                ) : (
                    <div></div>
                )}

                <h3>{gameOneData.tirePressureTablet}</h3>
                <div></div>
            </header>

            <div>
                <div className={styles.tire_card}>
                    <Button
                        color='white'
                        text='-'
                        onClick={() => adjustPressure('down')}
                        additionalStyle={{ fontSize: 'medium' }}
                    />
                    <div>
                        <p>{tirePressure.toFixed(2)} Bar</p>
                        <img src={TireImg} alt='pneu avant gauche' width={256} />
                        <h1> pneu {tireName}</h1>
                    </div>
                    <Button
                        color='white'
                        text='+'
                        onClick={() => adjustPressure('up')}
                        additionalStyle={{ fontSize: 'medium' }}
                    />
                </div>
            </div>
            <div>
                {device && device === 'red' && (
                    <Button
                        color='black'
                        text={data.global.validate}
                        onClick={handleTirePressureValidation}
                    />
                )}
            </div>
        </div>
    );
}
