import styles from './switch.module.scss';

interface SwitchProps {
    initialStatus?: boolean;
    clickHandler: () => void;
}
export default function Switch({
    initialStatus = false,
    clickHandler,
}: SwitchProps) {
    return (
        <>
            <label className={styles.switch}>
                {/* this empty string is needed to avoid a linter error */}
                {''}
                <input
                    type='checkbox'
                    onChange={clickHandler}
                    checked={initialStatus}
                />
                <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
        </>
    );
}
