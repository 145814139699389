import globalData from '@/data/global.json';
import styles from '@/features/components/waitingPlayers/createRoom.module.scss';
import Button from '@/features/devices/tablets/components/buttons/Button';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useState } from 'react';
import type { DeviceColorWithTvAndMediator } from '@/types/global/types';

interface CreateRoomProps {
    setRoom: (roomName: string) => void;
    color: DeviceColorWithTvAndMediator;
}

export default function CreateRoom({ setRoom, color }: CreateRoomProps) {
    const [text, setText] = useState('');
    const [isRoomValidate, setIsRoomValidate] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const handleConnectToSocketRoom = () => {
        emitSocketEvent('send_join_room', { device: color });
        setIsRoomValidate(true);
        if (color === 'mediator') setRoom(text);
    };

    const handleStartGame = () => {
        emitSocketEvent('send_start_game', { color });
        if (color !== 'mediator') setRoom(text);
    };

    return (
        <div className={styles.container}>
            <div>
                {!isRoomValidate ? (
                    <>
                        <input
                            type='text'
                            value={text}
                            placeholder={globalData.global.pinCode}
                            onChange={handleInputChange}
                        />
                        <button onClick={handleConnectToSocketRoom}>
                            {globalData.global.validate}
                        </button>
                    </>
                ) : (
                    <Button
                        text={globalData.global.connect}
                        color='black'
                        onClick={handleStartGame}
                    />
                )}
            </div>
        </div>
    );
}
