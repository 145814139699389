import data from '@/data/global.json';
import Switch from '@/features/devices/tablets/components/buttons/Switch';
import styles from '@/features/devices/tablets/orange/components/phone/phone.module.scss';
import { handleSetDoNotDisturb } from '@/services/global/globalHandleFunctions';
import { useGame1Store } from '@/store/games/game1Store';

export default function Settings() {
    const doNotDisturb = useGame1Store.use.isDoNotDisturbOn();

    return (
        <div className={styles.settings}>
            <div>
                <p>Désactiver</p>
                <Switch
                    initialStatus={doNotDisturb}
                    clickHandler={() => handleSetDoNotDisturb(!doNotDisturb)}
                />
                <p>{data.global.activate}</p>
            </div>
        </div>
    );
}
