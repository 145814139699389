import { handleReceiveCall } from '@/services/global/globalHandleFunctions';
import { useGame1Store } from '@/store/games/game1Store';
import { useEffect } from 'react';

const CALL_DURATION = 8000;
const MAX_SECOND_DELAY = 20000;

export const useReceiveCall = (): void => {
    const { isDoNotDisturbOn, isBeingCalled } = useGame1Store();

    useEffect(() => {
        if (isDoNotDisturbOn || isBeingCalled) return;

        const timeBeforeCall = Math.random() * MAX_SECOND_DELAY;
        const timeoutID = setTimeout(() => {
            handleReceiveCall(true);
        }, timeBeforeCall);

        return () => {
            clearTimeout(timeoutID);
        };
    }, [isDoNotDisturbOn, isBeingCalled]);

    useEffect(() => {
        if (!isBeingCalled) return;
        const timeoutID = setTimeout(() => {
            handleReceiveCall(false);
        }, CALL_DURATION);
        return () => {
            clearTimeout(timeoutID);
        };
    }, [isBeingCalled]);
};
