import styles from './phone.module.scss';

export default function Clock() {
    const time = new Date().toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
    });

    return <h1 className={styles.clock}>{time}</h1>;
}
